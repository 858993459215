import api from "@/scripts/modules/client/api/api"
import useClientStore, {ClientStore} from "@/scripts/core/stores/client.store";
import { AxiosResponse } from "axios";
import useGlobalStore, { GlobalStore } from "@/scripts/core/stores/global.store";
import ApiClientEditData from "@/scripts/structs/api/client_edit_data";
import ApiResult from "@/scripts/core/types/api_result";

class EditFormSystem
{
    clientStore: ClientStore = useClientStore();
    globalStore: GlobalStore = useGlobalStore();

    async validateClientEditData(client: ApiClientEditData): Promise<ApiResult>
    {
        this.globalStore.showLoading();
        return api.validateEditClient(client).then(
            (response: AxiosResponse) => 
            {
                this.globalStore.hideLoading();

                if(response.status == 200)
                {
                    return { result: true };    
                }
                else
                {
                    const payload = {errors: response.data.errors.fields}
                    return { result: false, payload: payload }; 
                }                
            }
        );
    }

    async updateClient(client: ApiClientEditData): Promise<ApiResult>
    {
        this.globalStore.showLoading();

        return api.updateClient(client.id, client).then(
            (response: AxiosResponse) => 
            {             
                this.globalStore.hideLoading();
                
                if(response.status == 200)
                {
                    const data = response.data;
                    this.clientStore.setClient(data.client);
                    this.clientStore.setPersonalData(data.personal_data);

                    return { result: true };
                }
                else 
                {
                    return { result: false};
                }                
            }
        );
    }
    
}

export default EditFormSystem;