import { defineStore } from 'pinia' 
import Settings from "./types/settings"

interface State 
{
    settings : Settings
}

const actions =
{
    setAll(data)
    {
        this.settings.set(data.settings);
    },

    setSettings(data) { this.settings.set(data); },
    unsetSettings() { this.settings.unset(); },
} 

const usePreloaderStore = defineStore("preloader", {
    state: (): State => ({
        settings : new Settings(),
      }),
    actions: actions
});

export default usePreloaderStore;
/** 
 * We need to export a new type with the return type of the usePreloaderStore, 
 * so we can correctly declare variables to store the return of the call
 */
export type PreloaderStore = ReturnType<typeof usePreloaderStore>