abstract class BaseState
{
    private static m_instance_count = 0;
    /** THIS VARIBALES IS PUBLIC BECAUSE OF THE WAY TYPESCRIPT HANDLES POLYMORPHISM */
    public m_uid = 0;
    /** THIS VARIBALES IS PUBLIC BECAUSE OF THE WAY TYPESCRIPT HANDLES POLYMORPHISM */
    public m_name = "";
    constructor(name: string)    
    {
        BaseState.m_instance_count++;
        this.m_uid = BaseState.m_instance_count;
        this.m_name = name;
    }

    get UID() : number { return this.m_uid; }
    get name() : string { return this.m_name; }

    abstract onEnter(): void;
    abstract onExit(): void;
}

export default BaseState;