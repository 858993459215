<template>
    <div class="d-flex flex-column search-area-height">
    <v-row class="px-5 pt-4 mx-0" style="margin-top: 10vh;">
        <v-col cols="12">
            <v-card class="pa-6 pb-16 " v-on:keyup.enter="submit" style="background-color: rgba(0, 0, 0, 0);" elevation="0">
                <v-row align="center">
                    <v-col cols="6" offset="3" lg="6" offset-lg="3" sm="12" offset-sm="0" class="text-center">
                        <v-row align="center">
                            <v-col cols="12" class="text-center" ><span class="text-white text-h5"><b>Passe o Cartão</b></span></v-col>
                        </v-row>
                        
                        <v-row align="center">
                            <v-col cols="12" class="text-center">
                                <CardInput :disabled="!isShiftOpenComputed" @valid-card="onValidCard" :has-error="card_has_error" 
                                @clear-errors="card_has_error = false" :is-search-input="true"></CardInput>
                            </v-col>                            
                        </v-row>

                        <v-row align="center" class="mt-4">
                            <v-col cols="6" class="text-center">
                                <v-btn color="white" v-on:click="submit" size="large" rounded :disabled="!isShiftOpenComputed">
                                <v-icon class="mr-2">mdi-magnify</v-icon> Procurar
                                </v-btn>
                            </v-col>
                            <v-col cols="6" class="text-center">
                                <v-btn color="white" v-on:click="openQRCode" size="large" rounded :disabled="disableQRBtnComputed">
                                <v-icon class="mr-2">mdi-qrcode-scan</v-icon>Qr Code
                                </v-btn>
                            </v-col>
                            <QRInput ref="qrInputRef" @show-error="onShowQrError" @submit-q-r="onSubmitQr"></QRInput>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
    <v-row align="end" class="mx-0">
        <v-col cols="12" class="text-center">
            <img src="@/assets/images/logo-energia-verdeclaro.png" alt="tuacar_logo" height="100%" style="margin-top: 10vh; margin-bottom: 0;">
        </v-col>
    </v-row>
    </div>
    <v-bottom-navigation grow class="bottom-navigation-height">
        <SearchPageButton to="/terminal/client/new" icon="mdi-account-plus" label="Novo Cliente" :disabled="!isShiftOpenComputed"></SearchPageButton>
        <SearchPageButton to="/terminal/card/new" icon="mdi-credit-card-plus" label="2ª Via Cartão" :disabled="!isShiftOpenComputed"></SearchPageButton>
        <SearchPageButton to="/terminal/shift" icon="mdi-file-chart" label="Turnos"></SearchPageButton>
        <SearchPageButton to="/terminal/saft/upload" icon="mdi-file-upload" label="Upload Saft"></SearchPageButton>
        <SearchPageButton to="/terminal/logout" icon="mdi-exit-to-app" label="Sair"></SearchPageButton>     
    </v-bottom-navigation>
    <ResultBar ref="resultBarRef" v-on:ok="onResultBarClose"></ResultBar>
</template>

<script setup lang="ts">

import TextFieldData from "@/scripts/structs/core/text_field_data";
import SearchPageButton from "./SearchPageButton.vue";
import { reactive, onMounted, computed, ref, useTemplateRef } from "vue";
import useAuthStore from "@/scripts/core/stores/auth.store";
import SearchSystem from "@/scripts/modules/systems/search_system";
import router from "@/scripts/core/router";

import ResultBar from "@/components/core/feedback/dialog/ResultBar.vue";
import CardInput from "@/components/core/input/CardInput.vue";
import QRInput from "@/components/core/input/QRInput.vue";

const card : TextFieldData = reactive(new TextFieldData());
const card_has_error = ref(false);


const authStore = useAuthStore();
const search_system: SearchSystem = new SearchSystem();

const result_bar_ref = useTemplateRef("resultBarRef");
const qr_input_ref = useTemplateRef("qrInputRef");

/** Lifetime hooks */
onMounted(async () =>
{
    search_system.loadSettings();//no await because we do not care about the result
});

/** Computed */
const isShiftOpenComputed = computed(() => { return authStore.isShiftOpen; });
//permanently disable the button
const disableQRBtnComputed = computed(() => { return true; });
//original block rule, commented for now
// const disableQRBtnComputed = computed(() => { return authStore.isShiftOpen && qr_code_scanner_data.error_alert_visible; });

/** Methods */
async function submit()
{
    qr_input_ref.value.stopCamera();
    card.clearErrors();
    card_has_error.value = false;//clear previous errors
    
    if(card.validate())
    {
        const result = await search_system.search(card.value);

        if(result.result)
        {
            router.replace(result.payload.path as string);
        }
        else
        {
            card_has_error.value = true;
            result_bar_ref.value.show(false, result.message);            
        }
    }
    else
    {
        card_has_error.value = true;
        result_bar_ref.value.show(false, "Cartão inválido.");
    }
}

function onValidCard(card_str: string)
{
    card.value = card_str;
    submit();
}

function onResultBarClose()
{    
    result_bar_ref.value.hide();    
}

function openQRCode()
{
    qr_input_ref.value.scanQRCode();
}

function onSubmitQr(qr_code: string)
{
    card.value = qr_code;
    submit();
}

function onShowQrError(message: string)
{
    result_bar_ref.value.show(false, message);
}
</script>

<style scoped>

.bottom-navigation-height
{
    height: 64px !important;/** this value for the height is the same as the toolbar */
    background-color: #eee !important;
}

.search-area-height
{
    height: calc(100vh - 128px) !important;/** remove the height of the toolbar and the bottom-navigation */
}

</style>