import BaseField from "./base_field.js";

class PasswordFieldData extends BaseField<string>
{
    show: boolean;

    constructor()
    {
        super();
        this.show = false;
    }

    clearValue(): void
    {
        this.value = "";
    }
}

export default PasswordFieldData;