import ClientRoutes from "./client/routes/routes";
import CardRoutes   from "./new_card/routes/routes";
import SaftRoutes   from "./saft/routes/routes";
import ShiftRoutes  from "./shift/routes/routes";
import SearchRoutes  from "./search/routes/routes";
import LogoutRoutes  from "./logout/routes/routes";

const routes =
{
    GetRoutes()
    {
        let routes = [];
        routes = routes.concat(ClientRoutes);
        routes = routes.concat(CardRoutes);
        routes = routes.concat(SaftRoutes);
        routes = routes.concat(ShiftRoutes);
        routes = routes.concat(SearchRoutes);
        routes = routes.concat(LogoutRoutes);
        
        return routes;
    }
};

export default routes;