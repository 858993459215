<template>
    <div>
        <NewCardDialog :show="show"></NewCardDialog>
    </div>
</template>

<script setup lang="ts">

import { ref } from 'vue';
import NewCardDialog from "./NewCardDialog.vue";

const show = ref(true);
</script>