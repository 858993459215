<template>
    <v-app>        
        <router-view></router-view>
        <Loader></Loader>
    </v-app>
</template>

<script lang="ts">
import Loader from "@/components/core/feedback/load/LoaderController.vue";

export default
{
    name: "app",

    components:
    {
        Loader
    },
}
</script>

<style>
.theme--light.v-application
{
    background: #fff0 !important;
}

.row {
  margin-top: 0;
  margin-bottom: 0;
}

.row + .row {
  margin-top: 0;
}

.center-input-text input {
  text-align: center
}

</style>