import BaseEnum from "./base_enum.js"
import BaseEnumEntry from "./base_enum_entry.js";

class TransactionTypeEnum extends BaseEnum
{
    DEBIT: BaseEnumEntry;
    CREDIT: BaseEnumEntry;
    BONUS: BaseEnumEntry;
    CORRECTION: BaseEnumEntry;
    NEW_CARD: BaseEnumEntry;
    DISCOUNT: BaseEnumEntry;
    constructor()
    {
        super();
        this.DEBIT              = { value : "debit",      text : "Débito"   };
        this.CREDIT             = { value : "credit",     text : "Crédito"  };
        this.BONUS              = { value : "bonus",      text : "Bónus"    };
        this.CORRECTION         = { value : "correction", text : "Correção" };
        this.NEW_CARD           = { value : "new_card", text : "Novo Cartão" };
        this.DISCOUNT           = { value : "discount", text : "Desconto" };
    }
}

const TransactionType = new TransactionTypeEnum();
export default TransactionType;