import Defaults from "./defaults";
import Alignment from "./enums/alignment";

class Spacing
{
    identation: number      = Defaults.spacing.identation;
    line_spacing: number    = Defaults.spacing.line_spacing;
    alignment: Alignment    = Defaults.spacing.alignment;
}

export default Spacing;