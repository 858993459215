import ClientData from "./interfaces/client_data";

class Client
{
    private m_id: number;
    private m_type: string;
    private m_observations: string;
    private m_loaded: boolean;

    constructor()
    {
        this.m_id = 0;
        this.m_type = "";
        this.m_observations = "";
        this.m_loaded = false;
    }

    get id(): number { return this.m_id; }
    get type(): string { return this.m_type; }
    get observations(): string { return this.m_observations; }
    get loaded(): boolean { return this.m_loaded; }

    set(data: ClientData): void
    {
        this.m_id = data.client_id;
        this.m_type = data.type;
        this.m_observations = data.observation;
        this.m_loaded = true;
    }

    clear(): void
    {
        this.m_id = 0;
        this.m_type = "";
        this.m_observations = "";
        this.m_loaded = false;
    }
}

export default Client;