import Alignment from "./enums/alignment";
import Colors from "./enums/colors";
import { PageSizes, StandardFonts } from "pdf-lib";

class Defaults
{
    static readonly margins = {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20
    };

    static readonly spacing = {
        identation: 20,
        line_spacing: 10,
        alignment: Alignment.LEFT
    }

    static readonly font = {
        main: StandardFonts.TimesRoman,
        size: 10,
        color: Colors.BLACK
    }

    static readonly page = {
        size: PageSizes.A4,
        width: PageSizes.A4[0],
        height: PageSizes.A4[1]
    }
}

export default Defaults;