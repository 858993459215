<template>
    
    <v-text-field :variant="variant" class="center-input-text" v-model="card.value" :disabled="disabled" 
        :label="card.label" :color="color" clearable @update:model-value="checkCardValue" :base-color="baseColor"
        :bg-color="inputBgColor()" @update:focused="inputFocusChanged" :autofocus="autofocus" :colour="color" >
    </v-text-field>
                            
</template>

<script setup lang="ts">

import TextFieldData from "@/scripts/structs/core/text_field_data";

import { reactive, onBeforeMount, onMounted, onUnmounted, ref, watch } from "vue";
import type { VTextField } from "vuetify/components";

type InputVariant = VTextField["variant"];

/** Props */
const props = defineProps({
    disabled: Boolean,
    hasError: { type: Boolean, default: false },
    showValidColor: { type: Boolean, default: false },
    blockUnfocusedInput: { type: Boolean, default: false },
    //This ugly thing is to avoid a type error with the vuetify component
    //The original variant is an union of several strings so be able to use it we need
    //to convert it to a string with that function cast thing
    variant: { type: String as () => InputVariant, default: "outlined" },
    autofocus: { type: Boolean, default: false },
    baseColor: { type: String, default: "white" },
    color: { type: String, default: "white" },
    isSearchInput: { type: Boolean, default: false }
});

/** Events */
const emit = defineEmits(["validCard", "clearErrors"]);

/** Variables */
const card : TextFieldData = reactive(new TextFieldData());
const valid_card = ref(false);
const input_focused = ref(false);

/** Lifetime hooks */
onBeforeMount(() =>
{
    card.clearValueAndErrors();
});

onMounted(async () =>
{
    window.addEventListener("keydown", keydown);
});

onUnmounted(() =>
{
    window.removeEventListener("keydown", keydown);
});

/** Watchers */
watch(() => card.value, () => {
    emit("clearErrors");
})

/** Methods */
async function checkCardValue(card_str: string)
{
    card.clearErrors();
    //check card string structure
    const card_regex = /^TUACARD\d{8}/;

    if(card_regex.test(card_str))
    {
        card.hint = "Estrutura válida";
        valid_card.value = true;   
        emit("validCard", card_str);
    }
    else
    {
        card.hint = "";
        valid_card.value = false;   
    }
}

async function keydown(event: KeyboardEvent)
{
    if(props.disabled)
        return;
    
    if(props.blockUnfocusedInput)
        return;

    if(!input_focused.value)
    {
        if(event.key === "Backspace")
            card.value = card.value.slice(0, -1);    
        else if(event.ctrlKey && event.key === "v")
        {
            card.value += await navigator.clipboard.readText();   
            checkCardValue(card.value);
        }
        else if( !event.ctrlKey && event.key.length == 1 && 
                event.key.match(/[a-z0-9]/i))//single letter or number, also last condition because of ctrl+c/v
        {
            card.value += event.key;       
            checkCardValue(card.value);
        }
    }
}

function inputFocusChanged(isFocused: boolean)
{
    input_focused.value = isFocused;
}

function inputBgColor()
{
    let retval = undefined;
    const card_has_value = card.value != null && card.value.length > 0;
    const in_focus = input_focused.value;

    //in focus or at least one character is typed without error
    if(in_focus)//focused
    {
        if(props.hasError)
            retval = "error";
        else
            retval = "white";
    }
    else//not focused
    {
        if(card_has_value)
        {
            if(props.hasError)
                retval = "error";
            else if(props.isSearchInput)
                retval = "white";
            else
                retval = undefined;
        }
        else
            retval = undefined;        
    }

    if(props.showValidColor)
        retval = "primary";

    return retval;
}
</script>

