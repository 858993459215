import SearchPage from "@/components/modules/search/SearchPage.vue";

const routes = [
    {
        name: "search",
        path: "/terminal/search",
        component: SearchPage,
        beforeEnter: (to, from, next) => {
            next()
        }
    },   
];

export default routes;