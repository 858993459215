<template>
    <v-row class="px-5 pt-4 mb-0" align="center">
        <v-col cols="12">
            <v-row align="center">
                <v-col cols="6" offset="3" class="text-center" v-if="isShiftOpenComputed"><span class="primary--text text-h6">Fechar turno atual?</span></v-col>
                <v-col cols="6" offset="3" class="text-center" v-else><span class="primary--text text-h6">Sem turno aberto.</span></v-col>
            </v-row>

            <v-row align="center">
                <v-col cols="6" offset="3" class="text-center">
                    <v-btn size="large" color="primary" v-on:click="submit" rounded :disabled="!isShiftOpenComputed">
                        <v-icon class="mr-2">mdi-close-outline</v-icon> Fechar</v-btn>
                </v-col>
            </v-row>
        </v-col>
        <ConfirmDialog :show="showConfirmationDialog" message="Confirmar encerramento do presente turno?" v-on:cancel="confirmationCancel" 
                v-on:ok="confirmationOk"></ConfirmDialog>
        <ResultBar ref="resultBarRef" v-on:ok="onResultBarClose"></ResultBar>
    </v-row>
</template>

<script setup lang="ts">
import ConfirmDialog from "@/components/core/feedback/dialog/ConfirmDialog.vue";

import { ref, computed, useTemplateRef } from "vue";
import ShiftSystem from "@/scripts/modules/systems/shift_system";
import ResultBar from "@/components/core/feedback/dialog/ResultBar.vue";
import { useRouter } from "vue-router";


const showConfirmationDialog = ref(false);
const result_bar_ref = useTemplateRef("resultBarRef");

const shift_system = new ShiftSystem();
const router = useRouter();

/** Computed */
const isShiftOpenComputed = computed(() => { return localStorage.shift ? true : false; });

/** Methods */
function submit()
{
    showConfirmationDialog.value = true;
}

function confirmationCancel()
{
    showConfirmationDialog.value = false;
}

async function confirmationOk()
{
    showConfirmationDialog.value = false;
    const result = await shift_system.closeShift();

    if(result.result)
    {
        result_bar_ref.value.show(true, "Turno encerrado com sucesso.");
    }
    else
    {
        result_bar_ref.value.show(false, "Erro ao encerrar o turno. Tente novamente.");
    }   
}

function onResultBarClose()
{
    result_bar_ref.value.hide();
    router.replace({name:"search"});//back to search page
}
</script>