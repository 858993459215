import BaseEnum from "./base_enum.js";
import BaseEnumEntry from "./base_enum_entry.js";

class GenderEnum extends BaseEnum
{
    MASCULINE: BaseEnumEntry;
    FEMININE: BaseEnumEntry;
    OTHER: BaseEnumEntry;
    COMPANY: BaseEnumEntry;
    constructor()
    {
        super();
        this.MASCULINE = { value : "masculine",  text : "Masculino"};
        this.FEMININE  = { value : "feminine",   text : "Feminino" };
        this.OTHER     = { value : "other",      text : "Outro"    };
        this.COMPANY   = { value : "company",    text : "Empresa"  };
    }
}

const Gender = new GenderEnum();
export default Gender;