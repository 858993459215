import api from "@/scripts/auth/api/api"
import useAuthStore, {AuthStore} from "@/scripts/core/stores/auth.store";
import { useRouter, type Router } from "vue-router";
import { AxiosResponse } from "axios";
import LoginData, { useLoginData } from "@/scripts/core/stores/types/interfaces/login_data";
import useGlobalStore, { GlobalStore } from "@/scripts/core/stores/global.store";
import ApiResult from "@/scripts/core/types/api_result";

class AuthSystem
{
    check_token: string | number | NodeJS.Timeout = null;
    authStore: AuthStore = useAuthStore();
    globalStore: GlobalStore = useGlobalStore();
    router: Router = useRouter();

    activateSessionTimetout()
    {
        //check if we should reload the page or "logout"
        this.reloadCheck();
        const time = 5000;//1 min  => 60000 milli |||| 5000 = 5s
        this.check_token = setInterval(() => 
        {
            //if we have not logged in we do not need to check anything
            if(!this.authStore.isLoggedIn)
                return;

            const date = new Date().getTime() / 1000;
            if(date > localStorage.token_expiration)//expired
            {
                this.logout();
            }
        }, time);//milliseconds
    }

    deactivateSessionTimetout()
    {
        //deactivate session timeout
        clearInterval(this.check_token);
    }

    async login(username: string, password: string): Promise<ApiResult>
    {
        this.globalStore.showLoading(); 
        
        return api.login(username, password).then(
            (response: AxiosResponse) => {

                this.globalStore.hideLoading();
                
                const data = response.data;
                if(response.status == 200)
                {
                    this.setLocalStorage(data);
                    this.authStore.login(data);
                    
                    //check where to go
                    const user = data.user;
                    if(user.shift_number <= 0)
                        return { result: true, payload: { path: "/shift"} };
                    else//all ok
                        return { result: true, payload: { path: "/terminal/search" } };
                }
                    
                else if(response.status == 400 || response.status == 401 || response.status == 403)
                {
                    return { result: false, message: "Username ou Password inválidos." };
                }
                else
                {
                    return { result: false, message: "Server Auth Error." };
                }
            }
        );
    }

    logout()
    {
        this.unsetLocalStorage();
        this.authStore.logout();

        this.router.replace("/");

        //deactivate session timeout
        clearInterval(this.check_token);
    }


    /**
     * \brief Check if we are logged in and we are only refreshing the page
     * \nIf yes we need to store the login info in the store as the reload deletes it
     */
    reloadCheck()
    {
        //token is set
        if(localStorage.token)
        {
            //check for expiration
            const date = new Date().getTime() / 1000;
            if(date > localStorage.token_expiration)//expired
            {
                this.logout();
                return;
            }

            //valid token date, so we continue
            const data: LoginData = useLoginData();
            data.token = localStorage.token;
            data.expiration = localStorage.token_expiration;
            data.worker = JSON.parse(localStorage.worker);
            data.user = JSON.parse(localStorage.user);
            data.shop = JSON.parse(localStorage.shop);

            this.authStore.login(data);
        }
    }

    setLocalStorage(data)
    {
        localStorage.token = data.token;
        localStorage.token_expiration = data.expiration;
        localStorage.worker = JSON.stringify(data.worker);
        localStorage.user = JSON.stringify(data.user);
        localStorage.shop = JSON.stringify(data.shop);

        if(data.user.shift_number > 0)
            localStorage.shift = data.user.shift_number;
    }

    unsetLocalStorage()
    {
        localStorage.token = "";
        localStorage.token_expiration = new Date().getTime() / 1000;
        localStorage.worker = "";
        localStorage.user = "";
        localStorage.shop = "";

        localStorage.removeItem("shift");
    }

    async validateShiftNumber(shift_number:string): Promise<ApiResult>
    {
        this.globalStore.showLoading();
        
        return api.validateShift(this.authStore.shop.id, shift_number).then(
            (response: AxiosResponse) => {

                this.globalStore.hideLoading();

                if(response.status == 200)
                {
                    //set shift_number
                    this.authStore.openShift(response.data.shift_number, response.data.shift_id);
                    return { result: true };
                }
                else if(response.status == 400)
                {
                    return { result: false, message: "O turno já foi fechado." };
                }
                else
                {
                    return { result: false, message: "Server Error." };
                }
            }
        );
    }

    async loadShops(): Promise<ApiResult>
    {
        this.globalStore.showLoading();
        
        return api.loadShops().then(
            (response: AxiosResponse) => {
                this.globalStore.hideLoading();
                if(response.status == 200)
                {
                    return { result: true, payload: response.data };
                }
                else if(response.status == 400)
                {
                    return { result: false, message: "O turno não foi aberto." };
                }
                else
                {
                    return { result: false, message: "Server Error." };
                }  
            }
        );
    }

    async validateShop(shop_id:number): Promise<ApiResult>
    {
        this.globalStore.showLoading();
        
        return api.validateShop(shop_id).then(
            (response: AxiosResponse) => {

                this.globalStore.hideLoading();

                if(response.status == 200)
                {
                    //set shift_number
                    this.authStore.openShift(response.data.shift_number, response.data.shift_id);
                    
                    localStorage.shop = JSON.stringify(response.data.shop);
                    this.authStore.setShop(response.data.shop);
                    
                    return { result: true, payload: { path: "/terminal/search"} };
                }
                else
                {
                    return { result: false, message: "Server Error." };
                }
            }
        );
    }
}

export default AuthSystem;