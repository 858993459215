import Client from "./types/client"
import PersonalData from "./types/personal_data"
import Balance from "./types/balance"
import Card from "./types/card"
import Transactions from "./types/transactions"
import { defineStore } from "pinia"

interface State 
{
    client: Client,
    balance: Balance,
    personalData: PersonalData,
    card: Card,
    transactions: Transactions,
}

const actions =
{
    setClient( data)          { this.client.set(data); },
    setBalance( data)         { this.balance.set(data); },
    setPersonalData( data)    { this.personalData.set(data); },
    setCard( data)            { this.card.set(data); },
    setTransactions( data)    { this.transactions.set(data); },

    clear()
    {
        this.client.clear();
        this.balance.clear();
        this.personalData.clear();
        this.card.clear();
        this.transactions.clear();
    }
}

const useClientStore = defineStore("client", {
    state: (): State => ({
        client : new Client(),
        balance : new Balance(),
        personalData : new PersonalData(),
        card : new Card(),
        transactions : new Transactions(),
      }),
    actions: actions
});

export default useClientStore;
/** 
 * We need to export a new type with the return type of the useClientStore, 
 * so we can correctly declare variables to store the return of the call
 */
export type ClientStore = ReturnType<typeof useClientStore>