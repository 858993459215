import SettingsData from "./interfaces/settings_data";

class Settings
{
    private m_loaded: boolean;
    private m_max_discount: number;
    private m_new_card_cost: number;

    constructor()
    {
        this.m_loaded = false;
        this.m_max_discount = 0;
        this.m_new_card_cost = 0;
    }

    get loaded(): boolean { return this.m_loaded; }
    get maxDiscount(): number { return this.m_max_discount; }
    get newCardCost(): number { return this.m_new_card_cost; }

    set(settings: SettingsData): void
    {
        this.m_max_discount = parseFloat(settings.max_discount);
        this.m_new_card_cost = parseFloat(settings.new_card_cost);
        this.m_loaded = true;
    }

    unset(): void
    {
        this.m_max_discount = 0;
        this.m_new_card_cost = 0;
        this.m_loaded = false;
    }
}

export default Settings;