
class QrCodeScannerData
{
    html5_qrcode_scanner = null;
    show_reader: boolean;
    stream = null;
    error_alert_visible: boolean;
    error_message: string;
    audio = null;
    qr_code_detected: boolean;


    constructor()
    {
        this.html5_qrcode_scanner = null;
        this.show_reader = false;
        this.stream = null;
        this.error_alert_visible = false;
        this.error_message = "";
        this.audio = null;
        this.qr_code_detected = false;
    }
}

export default QrCodeScannerData;
