<template>
    <div class="bg-terminal">        
        <TopBar></TopBar>
        <MainContent></MainContent>
    </div>
</template>

<script setup lang="ts">

import TopBar from "./TopBar.vue";
import MainContent from "./MainContent.vue";

import AuthSystem from "@/scripts/auth/systems/auth_system";
import { onMounted, onUnmounted } from "vue";

const auth_system: AuthSystem = new AuthSystem();

onMounted(()=> { auth_system.activateSessionTimetout(); });
onUnmounted(()=> { auth_system.deactivateSessionTimetout(); });

</script>

<style>
.bg-terminal
{
    background-image: url(@/assets/images/bg-pattern.png) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;    
    background-size: cover !important;
    height: 100%;
}
</style>