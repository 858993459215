<template>
    
    <v-dialog v-model="show" max-width="800" @click:outside="stopCamera">
        <v-card class="fill-height">
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="stopCamera">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="fill-height" style="width: 100%;">
                <div>
                    <canvas ref="canvas" id="canvas" style="display: none;"></canvas>
                    <div id="reader" class="fill-height" style="width: 100%;"></div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
                            
</template>

<script setup lang="ts">

import { reactive, onBeforeMount, onUnmounted, ref } from "vue";
import QrCodeScannerData from "@/scripts/structs/core/qr_code_scanner_data";
import { Html5QrcodeScanner } from "html5-qrcode";

defineExpose({scanQRCode, stopCamera});

/** Events */
const emit = defineEmits(["submitQR", "showError"]);

/** Variables */
const qr_code_scanner_data : QrCodeScannerData = reactive(new QrCodeScannerData());
const show = ref(false);

/** Lifetime hooks */
onBeforeMount(() =>
{
    qr_code_scanner_data.audio = new Audio("/sounds/success1.mp3");
});

onUnmounted(() =>
{
    stopCamera();
});

/** Methods */
async function scanQRCode() 
{
    qr_code_scanner_data.show_reader = true;
    show.value = true;
    try 
    {
        const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true,
        });
        qr_code_scanner_data.stream = stream;

        const canvas = this.$refs.canvas;
        canvas.width = 200;
        canvas.height = 200;

        qr_code_scanner_data.html5_qrcode_scanner = new Html5QrcodeScanner("reader", { fps: 50, qrbox: 500}, false);

        qr_code_scanner_data.html5_qrcode_scanner.render((qr_code_message) => {
            qr_code_scanner_data.qr_code_detected = true;
            playSound();
            setTimeout(() => { stopCamera();}, 500);
            emit("submitQR", qr_code_message);
        });
        removeStopCameraButton();
    } 
    catch (err) 
    {
        qr_code_scanner_data.error_message = "Ocorreu um erro com a câmera. Por favor, tente novamente.";
        qr_code_scanner_data.error_alert_visible = true;
        stopCamera();
        emit("showError", qr_code_scanner_data.error_message);
    }
}

function stopCamera()
{
    qr_code_scanner_data.show_reader = false;
    show.value = false;
    if (qr_code_scanner_data.html5_qrcode_scanner && qr_code_scanner_data.stream)
    {
        const tracks = qr_code_scanner_data.stream.getTracks();
        tracks.forEach((track) => track.stop());
        qr_code_scanner_data.stream = null;
        qr_code_scanner_data.html5_qrcode_scanner.clear();
        qr_code_scanner_data.html5_qrcode_scanner = null;
    }
}

function playSound() 
{
    qr_code_scanner_data.audio.play();
}

// remove stop camera button
function removeStopCameraButton() 
{
    const stop_button = document.getElementById("html5-qrcode-button-camera-stop");
    if (stop_button) 
        stop_button.innerHTML = "";
    else 
        setTimeout(() => { removeStopCameraButton(); }, 100);
}
</script>

