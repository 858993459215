<template>
    <FullscreenDialogBase v-model="showRef" title="Fecho de Turno">
        <CloseShift></CloseShift>
        <ShiftsList></ShiftsList>
    </FullscreenDialogBase>
</template>

<script setup lang="ts">
import FullscreenDialogBase from '@/components/core/feedback/dialog/FullscreenDialogBase.vue';
import { toRef } from "vue";
import ShiftsList from "./ShiftsList.vue"
import CloseShift from "./CloseShift.vue";

const props = defineProps({
    show: Boolean,
});

const showRef = toRef(props, "show");

</script>