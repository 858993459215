import NewCardPopUp from "@/components/modules/new_card/NewCardPopUp.vue";

const routes = [
    {
        name: "new_card",
        path: "/terminal/card/new",
        component: NewCardPopUp,
        beforeEnter: (to, from, next) => {
            next()
        }
    },
];

export default routes;