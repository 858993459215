import LogoutPopUp from "@/components/modules/logout/LogoutPopUp.vue";

const routes = [
    {
        name: "logout",
        path: "/terminal/logout",
        component: LogoutPopUp
    },
];

export default routes;