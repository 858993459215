<template>
    <v-col cols="12">
        <v-card class="pa-6 pb-16">
            <v-row class="mx-0" align="center" justify="center">
                <v-col cols="12" class="text-center">
                    <v-icon color="error" size="128">mdi-close-circle-outline</v-icon>
                </v-col>
            </v-row>
            <v-row class="mx-0" align="center" justify="center">
                <v-col cols="12" class="text-center">
                    <span class="text-h5">Ação não disponível pois não existe turno aberto. Por favor reinicie a sessão.</span>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col cols="6" class="text-center">
                    <v-btn color="error" v-on:click="exit">Sair</v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-col>
</template>

<script lang="ts">

import AuthSystem from '@/scripts/auth/systems/auth_system';

export default
{
    name: "ClosedShiftAlert",
    setup()
    {
        const auth_system: AuthSystem = new AuthSystem();

        function exit()
        {
            auth_system.logout();
        }

        return {
            exit
        }
    }

}
</script>