<template>
    <v-col cols="12" class="pt-0">
        <v-card class="pa-6">
            <v-row>
                <v-col cols="12" class="pl-0"><span class="primary--text text-h6">Dados Cliente</span></v-col>
                <v-col cols="12" class="pb-0"><b>{{nameComputed}}</b> </v-col>
                <v-col cols="3" lg="3" sm="6" class="pb-0"><b>Nif: </b>  {{nifComputed}} </v-col>
                <v-col cols="3" lg="3" sm="6" class="pb-0"><b>Tipo: </b> {{typeComputed}}</v-col>
                <v-col cols="3" lg="3" sm="6" class="pb-0"><b>Género: </b>  {{genderComputed}}</v-col>
                <v-col cols="3" lg="3" sm="6" class="pb-0"><b>Telemóvel: </b> {{cellphoneComputed}}  </v-col>
                <v-col cols="12" ><b>Comentário:</b>  {{observationsComputed}}  </v-col>
            </v-row>
        </v-card>
    </v-col>
</template>

<script setup lang="ts">
import Gender from "@/scripts/core/enums/gender.js"; 
import ClientType from "@/scripts/core/enums/client_type.js"; 
import useNewCardStore from "@/scripts/core/stores/new.card.store";
import { computed } from "vue";

const newCardStore = useNewCardStore();

/** Computed */
const nameComputed = computed(() => { return newCardStore.personalData.name; });
const nifComputed = computed(() => { return newCardStore.personalData.nif; });
const cellphoneComputed = computed(() => { return newCardStore.personalData.cellphone; });
const observationsComputed = computed(() => { return newCardStore.client.observations; });

const genderComputed = computed(() => { 
    return Gender.getText(newCardStore.personalData.gender);
});
const typeComputed = computed(() => { 
    return ClientType.getText(newCardStore.client.type);
});
</script>