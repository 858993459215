<template>
    <v-main>
        <router-view></router-view>
    </v-main>
</template>

<script lang="ts">


export default 
{
    
}
</script>