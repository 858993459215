<template>
    <v-card>
        <v-row class="px-5 pt-4 mb-0">
            <v-col cols="12">
                <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
                <v-card class="pa-6">
                    <v-row class="d-flex justify-space-between">
                        <ShiftReportHeaderSection title="Data" :data="dateComputed"></ShiftReportHeaderSection>
                        <ShiftReportHeaderSection title="Posto" :data="shopNameComputed"></ShiftReportHeaderSection>
                        <ShiftReportHeaderSection title="Número de Turno" :data="shiftNumberComputed.toString()"></ShiftReportHeaderSection>
                        <ShiftReportHeaderSection title="Total" :data="totalComputed"></ShiftReportHeaderSection>
                        <ShiftReportHeaderSection title="Número de transacções" :data="totalTransactionsComputed.toString()"></ShiftReportHeaderSection>
                    </v-row>
                    <v-row>
                        <v-divider thickness="10" class="border-opacity-100" color="primary"></v-divider>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="text-center">
                            <v-table hide-default-footer dense fixed-header height="64vh" density="compact">
                                <thead>
                                    <tr>
                                        <th class="text-center"><b>Hora</b></th>
                                        <th class="text-center"><b>NIF</b></th>
                                        <th class="text-center"><b>Nome</b></th>
                                        <th class="text-center"><b>N.º Cartão</b></th>
                                        <th class="text-center"><b>Valor Descontado</b></th>
                                        <th class="text-center"><b>Tipo</b></th>
                                    </tr>
                                </thead>
                                <tbody>                                    
                                    <tr v-for="item in entriesComputed" :key="item.hours">
                                        <td>{{ item.hours }}</td>
                                        <td>{{ item.nif }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.card }}</td>
                                        <td>{{ item.value }}</td>
                                        <td>{{ subtypeString(item.subtype) }}</td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-card>
</template>

<script setup lang="ts">

import ShiftReportData from '@/scripts/core/types/shift_report_data';
import { computed, ref, toRef  } from 'vue';
import ShiftReportHeaderSection from './ShiftReportHeaderSection.vue';
import TransactionSubType from '@/scripts/core/enums/transaction_subtype';

const props = defineProps({
    data: ShiftReportData,
});

const dataProp = toRef(props, "data");
const dataRef = ref(dataProp.value);

/** Computed */
const entriesComputed = computed(() => { return dataRef.value.transactions; });
const shiftNumberComputed = computed(() => { return dataRef.value.shift_number; });
const dateComputed = computed(() => { return dataRef.value.date; });
const totalComputed = computed(() => { return dataRef.value.total_value.toFixed(3); });
const totalTransactionsComputed = computed(() => { return dataRef.value.total_transactions; });
const shopNameComputed = computed(() => { return dataRef.value.shop_name; });

function subtypeString(subtype: string)
{
    return TransactionSubType.getText(subtype);
}
      
</script>