import UserData from "./interfaces/user_data";

class User
{
    private m_id:number;
    private m_shift_number:number;
    private m_shift_id:number;

    constructor()
    {
        this.m_id = 0;
        this.m_shift_number = 0;
        this.m_shift_id = 0;
    }

    get id(): number { return this.m_id; }
    get shiftNumber(): number { return this.m_shift_number; }
    get shiftId(): number { return this.m_shift_id; }

    set(data: UserData):void
    {
        this.m_id = data.user_id;
        this.m_shift_number = data.shift_number;
        this.m_shift_id = data.shift_id;
    }

    clear():void
    {
        this.m_id = 0;
        this.m_shift_number = 0;
        this.m_shift_id = 0;
    }

    openShift(number: number, shift_id: number): void
    {
        this.m_shift_number = number;
        this.m_shift_id = shift_id;
    }

    openShiftId(id)
    {
        this.shift_id = id;
    }

    closeShift()
    {
        this.m_shift_number = 0;
        this.m_shift_id = 0;
    }

    isShiftOpen(): boolean
    {
        return this.m_shift_id > 0;
    }
}

export default User;