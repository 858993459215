import useGlobalStore, { GlobalStore } from "@/scripts/core/stores/global.store";
import ApiResult from "@/scripts/core/types/api_result";
import api from "@/scripts/modules/client/api/api"
import { AxiosResponse } from "axios";

class CardSystem
{
    globalStore: GlobalStore;

    constructor()
    {
        this.globalStore = useGlobalStore();
    }

    async validateClientCard(card: string, client_id: number): Promise<ApiResult>
    {
        this.globalStore.showLoading();    
        //check if the card is valid
        return api.checkClientCard(card, client_id).then(
            (response: AxiosResponse) => 
            {
                this.globalStore.hideLoading();
                return { result: response.status == 200 };              
            }
        );
    }

    async validateNewClientCard(card: string): Promise<ApiResult>
    {
        this.globalStore.showLoading();            
        //check if the card is valid
        return api.checkNewClientCard(card).then(
            (response: AxiosResponse) => 
            {
                this.globalStore.hideLoading();

                return { result: response.status == 200 };                
            }
        );
    }
}

export default CardSystem;