<template>
    <div>
        <NewClientDialog :show="show"></NewClientDialog>
    </div>
</template>

<script setup lang="ts">
import NewClientDialog from './NewClientDialog.vue';
import { ref } from 'vue';
const show = ref(true);
</script>