import BaseField from "./base_field.js";

class SelectFieldEntry<T>
{
    value: T;
    title: string;
    disabled: boolean;
}

class SelectFieldData<T> extends BaseField<T>
{
    items: SelectFieldEntry<T>[];
    value: T;

    constructor()
    {
        super();
        this.items = [];
    }

    //{{ Items
    addItem(value: T, text: string, disabled: boolean = false): void
    {
        this.items.push({value: value, title: text, disabled: disabled});
    }    

    clearItems(): void
    {
        this.items.splice(0);
    }
    //}} /Items

    //{{ Validation    
    setDefaultValidationRules(): void
    {
        //not working
        this.rules = [
            (value) => { return !!(value) || "O campo não pode estar vazio"; }
        ];
    }
    //}} /Validation

    //{{ Value
    setDefault(): void
    {
        if(this.items.length)
        {
            this.value = this.items[0].value;
        }
        else
            this.value = null;
    }

    setValue(val: T)
    {
        this.value = null;//default

        for(let i = 0; i < this.items.length; ++i)
        {   
            if(this.items[i].value === val)
                this.value = this.items[i].value;
        }            
    }
    //}} /Value

}

export default SelectFieldData;