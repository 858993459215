import axios from "axios";

axios.interceptors.request.use(function(config)
{
    const token = localStorage.token;
    if(token)
    {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
})

const api = 
{    
    getLatest(payload)
    {
        const params = { params: {"user_id" : payload.user_id, 
                                "shop_id" : payload.shop_id,
                                "report_count" : payload.report_count}};

        return new Promise(
            (resolve) => {
                axios.get("/shift/latest", params)
                .then( response => { resolve(response); } )
                .catch( (error) => { resolve(error.response); } );
            }
        );
    },

    closeShift(payload)
    {
        return new Promise(
            (resolve) => {
                axios.post("/shift/close/"+payload.user_id+"/"+payload.shift_id)
                .then( response => { resolve(response); } )
                .catch( (error) => { resolve(error.response); } );
            }
        ); 
    },

    getReport(payload)
    {
        
        return new Promise(
            (resolve) => {
                axios.get("/shift/report/"+payload.user_id+"/"+payload.shift)
                .then( response => { resolve(response); } )
                .catch( (error) => { resolve(error.response); } );
            }
        );
    }
};

export default api;