import DiscountNumpadState from "./discount_numpad_state";

class PercentageDiscountState extends DiscountNumpadState
{
    constructor(total_balance: number, max_discount: number)
    {
        const PERCENTAGE_STATE = "PERCENTAGE"; 
        super(PERCENTAGE_STATE, total_balance, max_discount);
    }

    onEnter(): void {}
    onExit(): void {}

    pressed(character: string): void
    {
        if(this.m_total_balance <= 0)
            return;

        const zero_char = "0";
        const double_zero_char = "00";
        if((character === zero_char || character === double_zero_char) 
            && this.m_current_percentage_string === "000")
            return;
        
        this.m_current_percentage_string += character;

        //remove any reamaining leading zeros
        this.removeLeadingZeros();

        //cap the value to total_balance
        let current_percentage_float = parseFloat(this.m_current_percentage_string);
        current_percentage_float = Math.min(current_percentage_float, this.m_max_discount_percentage);//clamp max value
        //cap the value to 100%
        current_percentage_float = Math.min(current_percentage_float, 100);//clamp to 100% if needed

        this.m_current_percentage_string = Math.round(current_percentage_float * 100).toString();

        this.setLeadingZeros();        
    }

    backspace(): void
    {
        this.m_current_percentage_string = this.m_current_percentage_string.slice(0, -1);//remove last character

        this.setLeadingZeros();
    }

    private removeLeadingZeros(): void
    {
        //remove all '0' characters from the string. We will handle its position manually, if needed.
        this.m_current_percentage_string = this.m_current_percentage_string .replace(/^0+/, '');
    }

    private setLeadingZeros(): void
    {
        this.removeLeadingZeros();
        
        while(this.m_current_percentage_string.length < 3)
            this.m_current_percentage_string = "0" + this.m_current_percentage_string;
    }
}

export default PercentageDiscountState;