import Defaults from "./defaults";

class Margins
{
    top:    number  = Defaults.margins.top;
    right:  number  = Defaults.margins.right;
    bottom: number  = Defaults.margins.bottom;
    left:   number  = Defaults.margins.left;
}

export default Margins;