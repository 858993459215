<template>
    <FullscreenDialogBase v-model="showRef" title="Área do Cliente" @close="close" >
        <v-row class="px-5 pt-4 mb-0">
            <PersonalData v-if="!isSmallScreenComputed"></PersonalData>
            <Discount></Discount>
            <PersonalData v-if="isSmallScreenComputed"></PersonalData>
        </v-row>
    </FullscreenDialogBase>
</template>

<script setup lang="ts">
import PersonalData from "./show/PersonalData.vue";
import Discount from "./show/DiscountArea.vue";

import FullscreenDialogBase from '@/components/core/feedback/dialog/FullscreenDialogBase.vue';

import { toRef, computed } from "vue";
import { useDisplay } from 'vuetify'
import useClientStore from "@/scripts/core/stores/client.store";

const props = defineProps({
    show: Boolean,
});

const showRef = toRef(props, "show");

const display = useDisplay();

const isSmallScreenComputed = computed(()=>
{
    switch (display.name.value)
            {
                case 'xs': return true
                case 'sm': return true
                case 'md': return false
                case 'lg': return false
                case 'xl': return false
                default: return false
            }
});

function close()
{
    const clientStore = useClientStore();
    clientStore.clear();
}

</script>