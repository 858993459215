import axios from "axios";

axios.interceptors.request.use(function(config)
{
    const token = localStorage.token;
    if(token)
    {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
})

const api = 
{    
    //{{ Check/Validate/Add Card
    checkNewCard(card: string)
    {
        return new Promise(
            (resolve) => {
                axios.get("/card/check/"+card)
                .then( response => { resolve(response); } )
                .catch( (error) => { resolve(error.response); } );
            }
        ); 
    },


    addNewCard(card: string, client_id: number, payload)
    {
        return new Promise(
            (resolve) => {
                axios.patch("/card/add/"+card+"/"+client_id, payload)
                .then( response => { resolve(response); } )
                .catch( (error) => { resolve(error.response); } );
            }
        ); 
    },
    //}} Check/Validate/Add Card

    searchByNif(nif: string)
    {
        return new Promise(
            (resolve) => {
                axios.get("/client/nif/"+nif)
                .then( response => { resolve(response); } )
                .catch( (error) => { resolve(error.response); } );
            }
        );
    },

    
};

export default api;