<template>
    <v-col cols="12">
        <v-row align="center">
            <v-col cols="6" offset="3" class="text-center"><span class="primary--text text-h6">Procurar cliente por NIF</span></v-col>
        </v-row>

        <v-row align="center">
            <v-col cols="6" offset="3" class="text-center">
                <v-text-field :rules="nif.validationRules()" variant="filled" 
                    v-model="nif.value" class="center-input-text" autofocus
                    :error-messages="nif.error_msg" :label="nif.label" color="primary" clearable>
                </v-text-field>
            </v-col>
        </v-row>
        
        <v-row align="center">
            <v-col cols="6" offset="3" class="text-center">
                <v-btn size="large" color="primary" v-on:click="searchClient" rounded><v-icon class="mr-2">mdi-magnify</v-icon>Procurar</v-btn>
            </v-col>
        </v-row>
    </v-col>
</template>

<script setup lang="ts">

import NewCardSystem from "@/scripts/modules/systems/new_card_system";
import TextFieldData from "@/scripts/structs/core/text_field_data.js";
import { reactive } from "vue";

const nif : TextFieldData = reactive(new TextFieldData());

const new_card_system = new NewCardSystem();

/** Methods */
async function searchClient()
{
    nif.clearErrors();
    if(nif.validate())
    {
        const result = await new_card_system.searchByNif(nif.value.trim());

        if(!result.result)
        {
            if(result.message === "invalid_client")
                nif.setError("Cliente não encontrado");     
            else
                nif.setError("Cliente encontra-se suspenso");       
        }
    }
}
</script>