class BaseField<T>
{
    value: T;
    label: string;
    error: boolean;
    success: boolean;
    error_msg: string[];
    rules: ((arg: T) => boolean|string)[];//array de funções que recebem um T e retornam bool ou string
    disabled: boolean;
    hint: string;

    constructor()
    {
        this.value = null;
        this.label = "";
        this.error = false;
        this.success = false;
        this.error_msg = [];
        this.rules = [];
        this.disabled = false;
        this.hint = "";

        this.setDefaultValidationRules();
    }

    //{{ Value
    clearValue(): void
    {
        this.value = null;
    }

    clearValueAndErrors(): void
    {
        this.clearErrors();
        this.clearValue();
    }
    //}} /Value

    //{{ Rules
    addValidationRule(rule: (arg: T) => boolean|string): void
    {
        this.rules.push(rule);
    }

    setDefaultValidationRules(): void
    {
        this.rules = [
            (value) => { return !!value || "O campo não pode estar vazio" }
         ];
    }

    clearValidationRules(): void
    {
        this.rules.splice(0);
    }

    validationRules(): ((arg: T) => boolean|string)[]
    {
        return this.rules;
    }

    validate(): boolean
    {
        const errors = [];
        for(let i = 0; i<this.rules.length; ++i)
        {
            const check = this.rules[i](this.value);
            if(typeof check !== 'boolean')
                errors.push(check);
        }

        if(errors.length)//has elements
        {
            this.setErrorList(errors);
            return false;
        }
        
        this.clearErrors();
        return true;
    }
    //}} /Rules

    //{{ Errors
    setError(error: string): void
    {
        this.error = true;
        this.error_msg = [error];
    }

    setErrorList(errors: string[]): void
    {
        this.error = true;
        this.error_msg = errors;
    }

    clearErrors(): void
    {
        this.error = false;
        this.error_msg = [];
    }
    //}} /Errors

    //{{ Copy
    copy(other: BaseField<T>): void
    {
        this.value = other.value;
        this.label = other.label;
        this.error = other.error;
        this.success = other.success;
        this.error_msg = other.error_msg;
        this.rules = other.rules;
        this.disabled = other.disabled;
        this.hint = other.hint;
    }
    //}} /Copy
}

export default BaseField;