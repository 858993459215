import CardData from "./interfaces/card_data";

class Card
{
    private m_client_id: number;
    private m_card: string;

    constructor()
    {
        this.m_client_id = 0;
        this.m_card = "";
    }

    get card(): string { return this.m_card; }
    get clientId(): number { return this.m_client_id; }

    set(data: CardData): void
    {
        this.m_client_id = data.client_id;
        this.m_card = data.number;
    }

    clear(): void
    {
        this.m_client_id = 0;
        this.m_card = "";
    }
}

export default Card;