import User from "../user"
import Worker from "../worker"
import Shop from "../shop"

export default interface LoginData 
{
    token: string;
    expiration: string;
    user: User;
    worker: Worker;
    shop: Shop;
}

export function useLoginData(): LoginData
{
    return {
        token: "",
        expiration: "",
        user: new User(),
        worker: new Worker(),
        shop: new Shop()
    }
}