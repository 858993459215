<template>
    <div>
        <LogoutDialog :show="show" @ok="logout" @cancel="cancel"></LogoutDialog>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import LogoutDialog from "./LogoutDialog.vue";
import AuthSystem from "@/scripts/auth/systems/auth_system";
import { useRouter } from "vue-router";

const show = ref(true);
const auth_system: AuthSystem = new AuthSystem();
const router = useRouter();

function cancel()
{
    show.value = false;
    router.replace({name:"search"});//back to search page
}

function logout()
{
    auth_system.logout();
}
</script>