<template>
    <div>
        <ShowClientDialog :show="show"></ShowClientDialog>
    </div>
</template>

<script setup lang="ts">
import ShowClientDialog from "./ShowClientDialog.vue";
import { ref } from "vue";
const show = ref(true);
</script>