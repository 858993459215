<template>
    <v-dialog v-model="showRef" fullscreen transition="scroll-y-transition" persistent>
        <v-card class="fgbg">
            <v-toolbar dark color="background">
                <v-btn icon dark @click="close()">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-toolbar-title>Fecho de turno - Relatório</v-toolbar-title>
                <v-btn v-on:click="createPdf" color="white" outlined class="mr-2" :disabled="shift_report_data.print_disabled">
                    <v-icon class="mr-2">mdi-download</v-icon> Descarregar
                </v-btn>
            </v-toolbar>
        
            <ShiftReportBody :data="shift_report_data"></ShiftReportBody>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">

import { reactive, toRef, watch } from "vue";
import ShiftReportBody from "./report_sections/ShiftReportBody.vue";
import ShiftReportData from "@/scripts/core/types/shift_report_data";
import useAuthStore from '@/scripts/core/stores/auth.store';
import ShiftSystem from '@/scripts/modules/systems/shift_system';
import PDFReport from "@/scripts/modules/shift/pdf/pdf_report";
import useGlobalStore, { GlobalStore } from "@/scripts/core/stores/global.store";

const props = defineProps({
    show: Boolean,
    shift: Number
});

const showRef = toRef(props, "show");

const emit = defineEmits(["close"]);

const shift_system = new ShiftSystem();
const authState = useAuthStore();

const shift_report_data = reactive(new ShiftReportData());
const pdf_report: PDFReport = new PDFReport();

const globalStore: GlobalStore = useGlobalStore();


/** Watchers */
watch(showRef,async () => {
    if(showRef.value == true)
    {
        const result = await shift_system.getReport(props.shift);
        if(result.result)
            getReportResult(result.payload.report);
    }
});


/** Methods */
function getReportResult(report)
{    
    shift_report_data.date = report.date;
    shift_report_data.shift_number = report.shift_number;

    shift_report_data.total_value = report.total_value;
    shift_report_data.total_transactions = report.total_transactions;

    shift_report_data.transactions = report.transactions;
    shift_report_data.print_disabled = false;

    shift_report_data.shop_name = authState.shop.name;
}

function close()
{
    emit("close");
    shift_report_data.clear();
}

async function createPdf() 
{
    globalStore.showLoading();

    let filename = `relatorio_${shift_report_data.shop_name}_${shift_report_data.shift_number}_${shift_report_data.date}`;

    const pdfBytes = await pdf_report.generatePDF(shift_report_data, filename);

    //append extension
    filename = filename + '.pdf';

    download(new Blob([pdfBytes], { type: 'application/pdf' }), filename, "application/pdf");

    globalStore.hideLoading();
}

function download(blob: Blob, filename: string, type: string, openInNewTab: boolean = false) 
{
    if(openInNewTab)
    {
        const url = URL.createObjectURL(blob);
        const win = window.open(url, '_blank');
        win.focus();
    }
    else
    {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.type = type;
        a.target = '_blank';
        a.click();
        URL.revokeObjectURL(url);
    }
}
</script>

<style scoped>
.fgbg
{
    background-color: #eee;
    height: 100%;
}
</style>