<template>
    <div>
        <ShiftDialog :show="show"></ShiftDialog>
    </div>
</template>

<script setup lang="ts">

import ShiftDialog from "./ShiftDialog.vue";
import { ref } from 'vue';

const show = ref(true);
</script>