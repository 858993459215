import { PDFDocument, PDFImage, PDFPage, StandardFonts } from 'pdf-lib'
import ShiftReportData from "@/scripts/core/types/shift_report_data";
import { ReportTransaction } from '@/scripts/core/types/shift_report_data';
import DocumentState from './document_state';
import Alignment from './enums/alignment';
import Defaults from './defaults';
import TransactionSubType from '@/scripts/core/enums/transaction_subtype';

/**
 * ALL POSITION AND SIZE VALUES ARE IN POINTS
 * A4 PAGE SIZE: 595x842 POINTS
 */
class PDFReport
{
    async generatePDF(data: ShiftReportData, filename: string): Promise<Uint8Array>
    {
        const pdfDoc = await PDFDocument.create();
        const state: DocumentState = new DocumentState();

        //init needed fonts
        await state.initFonts(pdfDoc);

        //draw logo
        const logo_url = '/assets/images/pdf_logo.png';
        const logo_bytes = await fetch(logo_url).then((res) => res.arrayBuffer());
        const logo_img = await pdfDoc.embedPng(logo_bytes);

        //metadata
        this.setMetadata(pdfDoc, filename);

        //place cursor at the top left of the page
        state.cursorToTopLeft();        

        //create first page
        const pages = [pdfDoc.addPage(Defaults.page.size)];

        this.drawPageHeader(state, pages[0], logo_img);
        this.drawShiftHeader(state, pages[0], data);
        
        const list = data.transactions;
        
        this.drawTransactions(state, pages, list, pdfDoc, logo_img);

        //add page number to each page
        this.drawPageNumber(state, pages, data.shift_number, logo_img);

        return pdfDoc.save();
    }

    setMetadata(doc: PDFDocument, filename: string)
    {
        doc.setTitle(filename)
        doc.setAuthor('Tuacard - Terminal')
        doc.setSubject('Relatório de turno')
        doc.setCreationDate(new Date())
        doc.setModificationDate(new Date())
    }

    drawLogo(state: DocumentState, page: PDFPage, logo: PDFImage)
    {
        const png_size = logo.scale(0.2);

        page.drawImage(logo, {
            x: state.marginsGetLeft(),
            y: page.getHeight() - png_size.height - state.marginsGetTop(),
            width: png_size.width,
            height: png_size.height,
          })
    }

    drawText(state: DocumentState, page: PDFPage, text: string)
    {
        let x = state.cursorGetX(); 
        const y = state.cursorGetY();

        if(state.spacingGetAlignment() == Alignment.CENTER)
        {
            const textWidth = state.fontGetFont().widthOfTextAtSize(text, state.fontGetSize());
            x = x - textWidth / 2;
        }
        else if(state.spacingGetAlignment() == Alignment.RIGHT)
        {
            const textWidth = state.fontGetFont().widthOfTextAtSize(text, state.fontGetSize());
            x = x - textWidth;
        }
        //if the alightment is left, do nothing to the position

        this.drawTextAt(state, page, text, x, y);
    }

    drawTextAt(state: DocumentState, page: PDFPage, text: string, x: number, y: number)
    {
        const options = {
            x: x,
            y: y,
            size: state.fontGetSize(),
            font: state.fontGetFont(),
            color: state.fontGetColor(),
        }
        page.drawText(text, options);
    }

    drawFullLineCustom(state: DocumentState, page: PDFPage, thickness: number = 1)
    {
        page.drawLine({
            start: {
                x: state.marginsGetLeft(),
                y: state.cursorGetY()
            },
            end: {
                x: state.pageGetWidth() - state.marginsGetRight(),
                y: state.cursorGetY()
            },
            thickness: thickness,
            color: state.fontGetColor(),
        })
    }

    drawPageHeader(state: DocumentState, page: PDFPage, logo: PDFImage)
    {
        //draw title
        state.spacingSetAlignment(Alignment.CENTER);
        state.spacingSetLineSpacing(10);
        state.fontSetSize(20);

        state.cursorNewLine();
        this.drawText(state, page, 'Relatório de turno');
        state.cursorNewLine();

        //draw logo
        this.drawLogo(state, page, logo);

        this.drawFullLineCustom(state, page, 2);
    }

    drawShiftHeader(state: DocumentState, page: PDFPage, data: ShiftReportData)
    {
        state.fontSetSize(14);
        state.cursorNewLine();

        //header info
        const labels = ["Data", "Posto", "Nº de Turno", "Total €", "Nº Transações"];
        
        //calculate each label offset
        const offset = (state.pageGetWidth() - state.marginsGetLeft() * 2) / labels.length;//points

        let x = state.marginsGetLeft() + offset / 2;
        state.fontSetFont(StandardFonts.TimesRomanBold);

        for(const label of labels)
        {
            //adjust x to center text
            const textWidth = state.fontGetFont().widthOfTextAtSize(label, state.fontGetSize());
            const text_centered_x = x - textWidth / 2;
            this.drawTextAt(state, page, label, text_centered_x, state.cursorGetY());
            x += offset;//update to next position
        }

        state.fontSetFont(StandardFonts.TimesRoman);
        state.fontSetSize(10);
        //reset x offseted position
        x = state.marginsGetLeft() + offset / 2;
        state.cursorNewLine();
        
        const header_data = [ data.date, data.shop_name, data.shift_number.toString(), 
                            data.total_value.toFixed(2), data.transactions.length.toString()];

        for(const entry of header_data)
        {
            //adjust x to center text
            const textWidth = state.fontGetFont().widthOfTextAtSize(entry, state.fontGetSize());
            const text_centered_x = x - textWidth / 2;
            this.drawTextAt(state, page, entry, text_centered_x, state.cursorGetY());
            x += offset;//update to next position
        }

        state.cursorNewLine();
        this.drawFullLineCustom(state, page, 2);
    }

    drawTransactions(state: DocumentState, pages: PDFPage[], transactions: ReportTransaction[], pdfDoc: PDFDocument, logo: PDFImage)
    {
        state.cursorNewLine();

        this.drawTransactionsHeader(state, pages[0]);
        let current_page = 0;

        //transactions
        state.fontSetSize(10);
        state.fontSetFont(StandardFonts.TimesRoman);
        state.spacingSetLineSpacing(5);

        for(const transaction of transactions)
        {
            state.cursorNewLine();

            //check in we need new page
            if(state.cursorGetY() <= state.marginsGetBottom() * 2)
            {
                current_page++;
                pages.push(pdfDoc.addPage(Defaults.page.size));
                state.cursorToTopLeft();

                this.drawPageHeader(state, pages[current_page], logo);

                state.cursorNewLine();
                this.drawTransactionsHeader(state, pages[current_page]);
                state.cursorNewLine();
                
                //reset to transactions font and size
                state.fontSetSize(10);
                state.fontSetFont(StandardFonts.TimesRoman);
                state.spacingSetLineSpacing(5);
            }

            this.drawSingleTransaction(state, pages[current_page], transaction);
        }

        //Adiciona no fim do documento (documento processado por computador)
        state.cursorNewLine();
        state.cursorNewLine();
        state.spacingSetAlignment(Alignment.CENTER);
        this.drawText(state, pages[current_page], "(documento processado por computador)");
    }

    drawTransactionsHeader(state: DocumentState, page: PDFPage)
    {
        //header info
        const labels = ["Hora", "NIF", "Nome", "N.º Cartão", "Valor Descontado", "Tipo"];
        //calculate each label offset
        const offset = (state.pageGetWidth() - state.marginsGetLeft() * 2) / labels.length;//points

        let x = state.marginsGetLeft() + offset / 2;
        state.fontSetSize(12);
        state.fontSetFont(StandardFonts.TimesRomanBold);
        state.spacingSetLineSpacing(10);

        for(const label of labels)
        {
            //adjust x to center text
            const textWidth = state.fontGetFont().widthOfTextAtSize(label, state.fontGetSize());
            const text_centered_x = x - textWidth / 2;
            this.drawTextAt(state, page, label, text_centered_x, state.cursorGetY());
            x += offset;//update to next position
        }
    }

    drawSingleTransaction(state: DocumentState, page: PDFPage, transaction: ReportTransaction)
    {
        const values = [transaction.hours, transaction.nif, transaction.name, 
                        transaction.card, transaction.value, 
                        TransactionSubType.getText(transaction.subtype)];

        const offset = (state.pageGetWidth() - state.marginsGetLeft() * 2) / values.length;
        let x = state.marginsGetLeft() + offset / 2;
        
        for(const value of values)
        {
            //adjust x to center text
            const textWidth = state.fontGetFont().widthOfTextAtSize(value, state.fontGetSize());
            const text_centered_x = x - textWidth / 2;
            this.drawTextAt(state, page, value, text_centered_x, state.cursorGetY());
            x += offset;//update to next position
        }
    }

    drawPageNumber(state: DocumentState, pages: PDFPage[], shift_number: number, logo: PDFImage)
    {
        state.fontSetSize(10);
        state.fontSetFont(StandardFonts.TimesRoman);
        //the page and shift numbers have a custom loication in the page
        for(const page of pages)
        {
            const text = `Turno ${shift_number} - Página ${pages.indexOf(page) + 1} de ${pages.length}`;
            const textWidth = state.fontGetFont().widthOfTextAtSize(text, state.fontGetSize());
            const x = state.pageGetWidth() - state.marginsGetRight() - textWidth;
            const y = page.getHeight() - logo.scale(0.2).height - state.marginsGetTop();
            this.drawTextAt(state, page, text, x, y);
        }
    }
}

export default PDFReport;