class Auth
{
    private m_is_logged_in: boolean;
    private m_token: string;
    private m_token_expiration: string;

    constructor()
    {
        this.m_is_logged_in = false;
        this.m_token = "";
        this.m_token_expiration = "";
    }

    get token(): string { return this.m_token; }
    get tokenExpiration(): string { return this.m_token_expiration; }

    get isLoggedIn(): boolean { return this.m_is_logged_in; }

    login(token: string, expiration: string): void 
    {
        this.m_token = token;
        this.m_token_expiration = expiration;
        this.m_is_logged_in = true;
    }

    logout(): void
    {
        this.m_is_logged_in = false;
        this.m_token = "";
        this.m_token_expiration = "";
    }
}

export default Auth;