<template>
    <FullscreenDialogBase v-model="showRef" title="Novo Cliente">
        <v-row class="px-5 pt-4 mb-0" align="center">
            <v-col cols="6" offset="3" lg="6" offset-lg="3" sm="12" offset-sm="0" class="text-center">
                <v-row>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-row justify="center">
                                    <v-col cols="12" class="text-center text-h6"><span>Novo Cartão</span></v-col>
                                </v-row>

                                <v-row align="center" justify="center">
                                    <v-col cols="12">
                                        <CardInput @valid-card="onValidCard" :has-error="card_has_error" 
                                            @clear-errors="card_has_error = false" variant="filled" autofocus
                                            base-color="undefined" color="primary"
                                            :block-unfocused-input="true"></CardInput>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12"><span class="primary--text text-h6">Dados Cliente</span></v-col>
                            <v-col cols="12">
                                <v-text-field :rules="name.validationRules()" 
                                    v-model="name.value"
                                    :error-messages="name.error_msg" :error="name.error"
                                    :label="name.label" color="primary" :disabled="!enableClientFormComputed" autofocus>
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-phone-input
                                    defaultCountry="PT"
                                    :rules="cellphone.validationRules()" 
                                    v-model="cellphone.value"
                                    v-model:country="cellphone.country"
                                    :error-messages="cellphone.error_msg" :error="cellphone.error"
                                    :label="cellphone.label" color="primary"
                                    :disabled="!enableClientFormComputed"
                                    placeholder="" clearable />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field :rules="nif.validationRules()" 
                                    v-model="nif.value"
                                    :error-messages="nif.error_msg" :error="nif.error"
                                    :label="nif.label" color="primary" :disabled="!enableClientFormComputed">
                                </v-text-field>
                            </v-col>

                            <v-col cols="6">
                                <v-select :items="type.items" v-model="type.value" :label="type.label" :disabled="!enableClientFormComputed"></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select :items="gender.items" v-model="gender.value" :label="gender.label" :disabled="!enableClientFormComputed"></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="6">
                                <v-btn size="large" color="secondary" rounded v-on:click="resetForm" :disabled="!enableClientFormComputed || block_submit_btns">
                                    <v-icon class="mr-2">mdi-check-outline</v-icon> Limpar</v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn size="large" color="primary" rounded v-on:click="btnSaveClient" :disabled="!enableClientFormComputed || block_submit_btns">
                                    <v-icon class="mr-2">mdi-check-outline</v-icon> Registar</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>                
            </v-col>
        </v-row>
        <ResultBar ref="resultBarRef" v-on:ok="onResultBarClose"></ResultBar>
    </FullscreenDialogBase>
</template>

<script setup lang="ts">

import TextFieldData from "@/scripts/structs/core/text_field_data.js";
import TelFieldData from "@/scripts/structs/core/tel_field_data.js";
import SelectFieldData from "@/scripts/structs/core/select_field_data.js";

import ApiClientNewData from "@/scripts/structs/api/client_new_data.js";

import Gender from "@/scripts/core/enums/gender.js"; 
import ClientType from "@/scripts/core/enums/client_type.js"; 

import FullscreenDialogBase from '@/components/core/feedback/dialog/FullscreenDialogBase.vue';

import { toRef, ref, reactive, onBeforeMount, computed, useTemplateRef } from "vue";
import useAuthStore from "@/scripts/core/stores/auth.store";
import { countries, makePhone } from "v-phone-input";

import NewClientSystem from "@/scripts/modules/systems/new_form_system";
import CardSystem from "@/scripts/modules/systems/card_system";

import ResultBar from "@/components/core/feedback/dialog/ResultBar.vue";

import SearchSystem from "@/scripts/modules/systems/search_system";
import { useRouter } from "vue-router";
import CardInput from "@/components/core/input/CardInput.vue";

const props = defineProps({
    show: Boolean,
});

const showRef = toRef(props, "show");

const name : TextFieldData = reactive(new TextFieldData());
const nif : TextFieldData = reactive(new TextFieldData());
const cellphone : TelFieldData<string> = reactive(new TelFieldData());

const type : SelectFieldData<string> = reactive(new SelectFieldData());
const gender : SelectFieldData<string> = reactive(new SelectFieldData());

const card : TextFieldData = reactive(new TextFieldData());
const card_has_error = ref(false);
const enable_client_form = ref(false);

const authStore = useAuthStore();

const new_client_system = new NewClientSystem();
const client_saved_successfully = ref(false);

const source : string = "terminal";

const router = useRouter();
const result_bar_ref = useTemplateRef("resultBarRef");

const block_submit_btns = ref(false);

/** Lifetime hooks */
onBeforeMount(() =>
{
    name.label = "Nome*";
    cellphone.label = "Telemóvel*";
    nif.label = "Nif*";

    //create selects
    //fill selects
    gender.label="Género*";
    let genders = Gender.toArray();        
    for(let key in genders)
    {
        gender.addItem(genders[key].value, genders[key].text);
    }
    gender.setDefault();

    type.label="Tipo*";
    let types = ClientType.toArray();        
    for(let key in types)
    {
        type.addItem(types[key].value, types[key].text);
    }
    type.setDefault();

    //reset form
    clearForm();
});


/** Computed */
const enableClientFormComputed = computed(() => { return enable_client_form.value; });

/** Methods */
async function btnSaveClient()
{
    clearErrors();
    client_saved_successfully.value = false;//clear flag just to be sure

    if(validateForm())
    {
        const country_code = countries.find((c) => c.iso2 === cellphone.country)?.dialCode;

        const client_data = new ApiClientNewData(
            name.value,
            nif.value, country_code, cellphone.value,
            type.value, gender.value, card.value,
            authStore.user.id,
            source,
            authStore.shop.id);

        const validate_result = await new_client_system.validateClientData(client_data);    
        if(validate_result.result)//validation successful
        {
            //save client
            const save_result = await new_client_system.newClient(client_data);
            if(save_result.result)
            {
                result_bar_ref.value.show(true, "Cliente registado com sucesso.");
                client_saved_successfully.value = true;
                block_submit_btns.value = true;
            }
            else
            { 
                result_bar_ref.value.show(false, "Erro ao registrar o cliente. Por favor, tente novamente.");  
                block_submit_btns.value = false;     
            }
        }
        else
        {
            showServerValidationErrors(validate_result.payload.errors);      
        }      
    }
}

function validateForm()
{
    cellphone.valid = makePhone(cellphone.value).valid;
    //this is to avoid bool optimization because all CheckEmptyInput need to run as they can set an error
    const check = [ name.validate(), nif.validate(), cellphone.validate(),
                    (type.value !== ""), (gender.value !== "") ];
    
    let result = true;
    for(let i=0; i<check.length; ++i)
        result = result && check[i];
    return result;
}

function resetForm()
{
    clearForm();
    clearErrors();
}

function clearForm()
{
    name.value = "";
    nif.value = "";
    cellphone.value = "";
    type.setDefault();
    gender.setDefault();
}

function clearErrors()
{
    name.clearErrors();
    nif.clearErrors();
    cellphone.clearErrors();
    type.clearErrors();
    gender.clearErrors();
}

function showServerValidationErrors(data)
{
    data.forEach(element => 
    {
        switch(element.field)
        {
            case "name": name.setErrorList(element.errors); break;

            case "nif": nif.setErrorList(element.errors); break;
            case "cellphone": cellphone.setErrorList(element.errors); break;

            case "type": type.setErrorList(element.errors); break;
            case "gender": gender.setErrorList(element.errors); break;
        }
    });
}

async function apiValidateCard(card_str: string)
{
    card.clearErrors();
    card_has_error.value = false;//clear previous errors
    enable_client_form.value = false;//block form

    //check if the card is valid
    const card_system = new CardSystem();
    const result = await card_system.validateNewClientCard(card_str);

    if(result.result)
    {
        result_bar_ref.value.show(true, "Cartão válido.");
        
        enable_client_form.value = true;   
        card_has_error.value = false;
    }
    else
    {
        result_bar_ref.value.show(false, "Cartão inválido.");

        enable_client_form.value = false;          
        card_has_error.value = true;
    }
}

async function onResultBarClose(is_success: boolean)
{
    result_bar_ref.value.hide();

    if(is_success && client_saved_successfully.value)//if successful and the form is enabled
    {
        //redirect to client show by using the search system
        const search_system = new SearchSystem();
        const result = await search_system.search(card.value);

        if(result.result)
        {
            router.replace(result.payload.path as string);
        }
        else
        {
            //if the automatic redirect fails we go back to the initial page
            router.replace({name:"search"});//back to search page
        } 
    }       
}

function onValidCard(card_str: string)
{
    card.value = card_str;
    apiValidateCard(card_str);
}

</script>