import ShopData from "./interfaces/shop_data";

class Shop
{
    private m_id: number;
    private m_name: string;
    
    constructor()
    {
        this.m_id = 0;
        this.m_name = "";
    }

    get id(): number { return this.m_id; }
    get name(): string { return this.m_name; }

    set(shop: ShopData): void
    {
        this.m_id = shop.shop_id;
        this.m_name = shop.name;
    }

    clear(): void
    {
        this.m_id = 0;
        this.m_name = "";
    }
}

export default Shop;