<template>
    <v-col cols="12" class="pt-0">
        <v-card class="pa-6 pb-16" :disabled="!settingsLoadedComputed"> 
            <v-alert type="error" v-if="!settingsLoadedComputed">Erro a carregar informação de novo cartão. Recarregue a página.</v-alert>
            <v-row justify="center">
                <v-col cols="6" class="text-center"><span>Passe o Novo Cartão</span></v-col>
            </v-row>

            <v-row align="center" justify="center">
                <v-col cols="6">
                    <CardInput @valid-card="onValidCard" :has-error="card_has_error" 
                        @clear-errors="card_has_error = false" variant="filled" autofocus
                        base-color="undefined" color="primary" :disabled="lock_input"></CardInput>
                </v-col>
            </v-row>

            <v-row align="center" justify="center">
                <v-col cols="6" class="text-center">
                    <v-btn size="large" color="primary" v-on:click="checkCardValue" rounded :disabled="lock_btn">
                        <v-icon class="mr-2">mdi-content-save-outline</v-icon>Associar Cartão</v-btn>
                </v-col>
            </v-row>

            <ResultBar ref="resultBarRef" v-on:ok="onResultBarClose"></ResultBar>
        </v-card>
    </v-col>
</template>

<script setup lang="ts">

import TextFieldData from "@/scripts/structs/core/text_field_data.js";

import { computed, reactive, ref, useTemplateRef } from "vue";
import usePreloaderStore from "@/scripts/core/stores/preloader.store";
import NewCardSystem from "@/scripts/modules/systems/new_card_system";
import useNewCardStore from "@/scripts/core/stores/new.card.store";
import ResultBar from "@/components/core/feedback/dialog/ResultBar.vue";
import { useRouter } from "vue-router";
import CardInput from "@/components/core/input/CardInput.vue";

const card : TextFieldData = reactive(new TextFieldData());
const card_has_error = ref(false);

const preloaderStore = usePreloaderStore();
const newCardStore = useNewCardStore();

const new_card_system = new NewCardSystem();
const lock_btn = ref(false);
const lock_input = ref(false);

const result_bar_ref = useTemplateRef("resultBarRef");
const router = useRouter();

/** Computed */
const settingsLoadedComputed = computed(()=> { return preloaderStore.settings.loaded; });

/** Methods */
function onResultBarClose(is_success: boolean)
{
    result_bar_ref.value.hide();
    //if successful
    if(is_success)
    {
        new_card_system.closeClient();
        router.replace({name:"search"});//back to search page
    }
}

async function addCardToClient()
{
    card.clearErrors();
 
    const save_result = await new_card_system.addNewCard(card.value, newCardStore.client.id);

    if(save_result.result)
    {
        lock_input.value = true;
        lock_btn.value = true;
        result_bar_ref.value.show(true, "Cartão associado com sucesso. Aguarde a sua ativação.");
    }
    else
    {
        lock_input.value = false;
        lock_btn.value = false;
        result_bar_ref.value.show(false, "Erro ao associar o cartão. Tente novamente.");
    }
}

async function checkCardValue()
{
    card.clearErrors();
    lock_btn.value = true; 
    card_has_error.value = false;

    //check if the card is valid
    const result = await new_card_system.validateNewCard(card.value);

    if(result.result)
    {
        addCardToClient();  
        lock_btn.value = false; 
    }
    else
    {
        result_bar_ref.value.show(false, "Cartão inválido.");
        card_has_error.value = true;
    }
}

function onValidCard(card_str: string)
{
    card.value = card_str;
    checkCardValue();
}
</script>