import WorkerData from "./interfaces/worker_data";

class Worker
{
    private m_id: number;
    private m_name: string;

    constructor()
    {
        this.m_id = 0;
        this.m_name = "";
    }

    get id(): number { return this.m_id; }
    get name(): string { return this.m_name; }

    set(worker: WorkerData): void
    {
        this.m_id = worker.id;
        this.m_name = worker.name;
    }

    clear(): void
    {
        this.m_id = 0;
        this.m_name = "";
    }
}

export default Worker;