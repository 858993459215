import LoginForm from "@/components/auth/LoginForm.vue";
import Shift from "@/components/auth/ShiftSelect.vue";

const routes = {
    GetRoutes()
    {
        let routes = [];
        routes = routes.concat(this.paths);
        return routes;
    },

    paths:[
        {
            path: "/",
            name: "login",
            component: LoginForm,
            beforeEnter: (to, from, next) => {
                if (localStorage.token)
                {
                    if(!localStorage.shift)
                        next({ name: 'shift_select' });
                    else
                        next({ name: 'search' }); 
                }
                else { next(); }
            }
        },
        {
            path: "/shift",
            name: "shift_select",
            component: Shift,
        },
    ]
};

export default routes;