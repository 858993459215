<template>
    <v-dialog v-model="show" fullscreen transition="scroll-y-transition" persistent @keydown.esc="escClose">
        <v-card class="fgbg">
            <v-toolbar dark color="background">
            <v-btn v-if="!disable_close" icon dark @click="close()">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            </v-toolbar>
        
            <slot></slot>
      </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { toRef } from "vue";
import { useRouter } from "vue-router";

interface Props
{
    title?: string;
    disable_close?: boolean;
    redirect?: boolean;
}

const props = withDefaults(defineProps<Props>(),
    {
        title: "",
        disable_close: false,
        redirect: true
    }
);

const emit = defineEmits(["close"]);

const show = defineModel({default: false});
const title = toRef(props, "title");
const router = useRouter();

function close()
{
    emit("close");
    if(props.redirect)
        router.replace({name:"search"});
}

function escClose()
{
    if(props.disable_close)
        return;
    emit("close");
    if(props.redirect)
        router.replace({name:"search"});
}
</script>

<style scoped>
.fgbg
{
    background-color: #eee;
    height: 100%;
}
</style>