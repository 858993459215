import axios from "axios";

axios.interceptors.request.use(function(config)
{
    const token = localStorage.token;
    if(token)
    {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
})

const api = 
{
    //helper data
    All()
    {
        return new Promise(
            (resolve) => {
                axios.get("/helpers")
                .then( (response) => { resolve(response); } )
                .catch( (error) => { resolve(error.response); } );
            }
        ); 
    },

    Gender()
    {
        return new Promise(
            (resolve) => {
                axios.get("/helper/gender")
                .then( (response) => { resolve(response); } )
                .catch( (error) => { resolve(error.response); } );
            }
        ); 
    },

    ClientType()
    {
        return new Promise(
            (resolve) => {
                axios.get("/helper/client_type")
                .then( (response) => { resolve(response); } )
                .catch( (error) => { resolve(error.response); } );
            }
        ); 
    },
    
    TransactionType()
    {
        return new Promise(
            (resolve) => {
                axios.get("/helper/transaction_type")
                .then( (response) => { resolve(response); } )
                .catch( (error) => { resolve(error.response); } );
            }
        ); 
    },

    GetSettings()
    {
        return new Promise( 
            (resolve) => { 
                axios.get("/settings")
                    .then( (response) => { resolve(response); } )
                    .catch( (error) => { resolve(error.response); } ); 
            } 
        );
    },
};

export default api;