import Defaults from './defaults';

class Page
{
    /** Variables */
    private m_width: number = Defaults.page.width;
    private m_height: number = Defaults.page.height;

    /** Getters and Setters */
    get width(): number { return this.m_width; }
    get height(): number { return this.m_height; }
}

export default Page;