<template>
    <div id="overlay" v-bind:style="{display: showComputed}" v-bind:class="fullpageComputed">
        <div v-bind:class="loadComputed"></div>
    </div>
</template>

<script setup lang="ts">

import { computed } from 'vue';

const props = defineProps({
  show: Boolean,
  fullpage: Boolean,
  load: Boolean,
});

const showComputed = computed(() => { return props.show ? "block" : "none"; });
const fullpageComputed = computed(() => { return props.fullpage ? "overlay-fullpage" : "overlay-div"; });
const loadComputed = computed(() => { return props.load ? "load" : ""; });

</script>

<style>
#overlay 
{    
    background-color: rgba(0,0,0,0.5);
    z-index: 10000;
    cursor: pointer; 
}

.overlay-fullpage
{
    position: fixed;
    width: 100%; 
    height: 100%; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.overlay-div
{
    position: absolute;
    width: 100%; 
    height: 100%; 
    top: inherit;
    left: inherit;
    right: inherit;
    bottom: inherit;
    padding: inherit; 
}

.load
{
    /* border: 16px solid #f3f3f3; Light grey */
    /* border-top: 16px solid #3498db; Blue */
    /* border-radius: 50%; */
    width: 256px;
    height: 256px;
    animation: spin 5s linear infinite;
    /* animation: spin 2s linear infinite; */
    position:absolute;
    top: 50%;
    left: 50%;
    margin: -128px 0 0 -128px;
    background-image: url("/assets/images/favicon.ico");
}

@keyframes spin 
{
    0% { transform: rotateY(0deg); }
    50% { transform: rotateY(360deg); }
    100% { transform: rotateY(0deg); }
}

</style>