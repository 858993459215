import UploadPopUp from "@/components/modules/saft/UploadPopUp.vue";

const routes = [
    {
        name: "upload_saft",
        path: "/terminal/saft/upload",
        component: UploadPopUp
    },
];

export default routes;