<template>
    <v-snackbar v-model="data.show" :color="colorComputed" location="top" 
            :timeout="-1" style="margin-top: 10vh;">

            <span class="text-h6">
                <v-icon v-if="data.success">mdi-check-circle-outline</v-icon>
                <v-icon v-if="!data.success">mdi-close-circle-outline</v-icon>
                {{data.message}}
            </span>
        <template v-slot:actions>            
            <v-btn  variant="text" @click="ok()">
                <v-progress-circular :model-value="progressComputed" :size="35" v-if="showProgress">
                    <span>OK</span>
                </v-progress-circular>
                <span v-else>OK</span>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script setup lang="ts">
import ResultBarData from '@/scripts/core/feedback/structs/result_bar_data';
import { computed, reactive, ref } from 'vue';

const data = reactive(new ResultBarData());
const emit = defineEmits(['ok']);

defineExpose({show, hide});

let interval_token = null;
const ttl = ref(0);

/** Computed */
const colorComputed = computed(() => { return data.success ? "success" : "error"; });
const progressComputed = computed(() => { return ttl.value/data.timeout * 100; });
const showProgress = computed(() => { return data.timeout > 0; });

/** Functions */
function ok()
{
    emit("ok", data.success);
    //clear the interval if it exists
    clearInterval(interval_token);
    interval_token = null;
}

function startTimeout()
{
    if(data.timeout < 0) return;

    const delta_time = 100;
    ttl.value = data.timeout;

    interval_token = setInterval(() => {
        //timeout reached
        if(ttl.value <= 0)
        {
            clearInterval(interval_token);
            interval_token = null;
            ok();       
        }

        //update timer
        ttl.value -= delta_time;
    }, delta_time);//in ms
}

/** Deafult timeout of 10 seconds */
function show(isSuccess: boolean, message: string, timeout: number = 10000)
{
    data.success = isSuccess;
    data.message = message;
    data.timeout = timeout;
    data.show = true

    if(interval_token !== null)//timeout is already running
    {
        clearInterval(interval_token);
        interval_token = null;
    }

    //restart timer
    startTimeout();    
}

function hide()
{
    data.show = false;
    clearInterval(interval_token);
    interval_token = null;
}
</script>