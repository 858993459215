export interface ReportTransaction
{
    //the object returned by the server has only strings
    hours: string;
    nif: string;
    name: string;
    card: string;
    value: string;
    type: string;
    subtype: string;
}


class ShiftReportData
{
    public date: string = "";
    public shift_number: number = 0;
    public total_value: number = 0;
    public total_transactions: number = 0;
    public transactions: ReportTransaction[] = [];
    public print_disabled: boolean = false;
    public shop_name: string = "";

    clear(): void
    {
        this.date = "";
        this.shift_number = 0;
        this.total_value = 0;
        this.total_transactions = 0;
        this.transactions = [];
        this.print_disabled = false;
        this.shop_name = "";
    }
}

export default ShiftReportData;