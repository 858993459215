import DocumentState from './document_state';
import Alignment from './enums/alignment';
import Position from './position';

class Cursor
{
    /** Variables */
    private m_cursor: Position = new Position();    

    /** Getters and Setters */
    get(): Position { return this.m_cursor; }
    set(position: Position) { this.m_cursor = position; }

    /** Methods */
    newLine(state: DocumentState): void 
    {
        this.m_cursor.y -= (state.fontGetSize() + state.spacingGetLineSpacing());
        this.alignX(state);
    }

    alignX(state: DocumentState): void
    {
        const alignment = state.spacingGetAlignment();
        //we need to set the new cursor position
        if(alignment == Alignment.LEFT)
            this.toLeft(state);
        else if(alignment == Alignment.CENTER)
            this.toCenter(state);
        else if(alignment == Alignment.RIGHT)
            this.toRight(state);
    }

    toTopLeft(state: DocumentState): void 
    {
        this.m_cursor.x = state.marginsGetLeft();
        this.m_cursor.y = state.pageGetHeight() - state.marginsGetTop();
    }

    /** Distance based */
    moveRight(distance: number): void { this.m_cursor.x += distance; }
    moveLeft(distance: number): void { this.m_cursor.x -= distance; }
    moveDown(distance: number): void { this.m_cursor.y -= distance; }
    moveUp(distance: number): void { this.m_cursor.y += distance; }

    /** Position based */
    moveTo(position: Position): void { this.m_cursor = position; }
    moveToX(x: number): void { this.m_cursor.x = x; }
    moveToY(y: number): void { this.m_cursor.y = y; }

    /** Alignment based */
    toLeft(state: DocumentState): void { this.m_cursor.x = state.marginsGetLeft(); }
    toRight(state: DocumentState): void { this.m_cursor.x = state.pageGetWidth() - state.marginsGetRight(); }
    toCenter(state: DocumentState): void { this.m_cursor.x = state.pageGetWidth() / 2; }
}

export default Cursor;