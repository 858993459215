import BaseState from "./base_state";

class FiniteStateMachine<T extends BaseState>
{
    private m_current_state: T;
    private m_states: Map<string, T> = new Map<string, T>();

    constructor()
    {
        this.m_current_state = null;
    }    

    get currentState(): T { return this.m_current_state; }

    addState(key: string, state: T): void
    {
        if(!this.m_states.has(key))
            this.m_states.set(key, state);
    }

    removeState(key: string): void
    {
        this.m_states.delete(key);
    }

    changeState(key: string): void
    {
        if(!this.m_states.has(key))
            return;
        if(this.m_current_state)
            this.m_current_state.onExit();
        this.m_current_state = this.m_states.get(key);
        this.m_current_state.onEnter();
    }

    isCurrentState(key: string): boolean
    {
        if(!this.m_states.has(key))
            return false;
        return this.m_current_state === this.m_states.get(key);
    }
}

export default FiniteStateMachine;