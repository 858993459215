<template>
    <v-row>
        <v-col cols="12">
            <v-row justify="center">
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-row class="text-center calc">
                                <v-col cols="12" v-if="inNumericMode()">€ {{discountComputed}}</v-col>
                                <v-col cols="12" v-else>{{discountPercentageComputed}}% (€ {{discountPercentageDiscountComputed}})</v-col>
                            </v-row> 
                            <v-row class="text-center">
                                <v-col cols="12"><span class="text-error">{{validation_error_msg}}</span></v-col>
                            </v-row> 
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-row justify="center">
                                <v-col cols="2" class="text-center"><v-btn class="calc_btn" v-on:click="pressed('1')">{{appendPercent("1")}}</v-btn></v-col>
                                <v-col cols="2" class="text-center"><v-btn class="calc_btn" v-on:click="pressed('2')">{{appendPercent("2")}}</v-btn></v-col>
                                <v-col cols="2" class="text-center"><v-btn class="calc_btn" v-on:click="pressed('3')">{{appendPercent("3")}}</v-btn></v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="2" class="text-center"><v-btn class="calc_btn" v-on:click="pressedPercentage(0.25)"><b>25%</b></v-btn></v-col>
                                <v-col cols="2" class="text-center"><v-btn class="calc_btn" v-on:click="pressedPercentage(0.5)"><b>50%</b></v-btn></v-col>
                            </v-row> 
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-row justify="center">
                                <v-col cols="2" class="text-center"><v-btn class="calc_btn" v-on:click="pressed('4')">{{appendPercent("4")}}</v-btn></v-col>
                                <v-col cols="2" class="text-center"><v-btn class="calc_btn" v-on:click="pressed('5')">{{appendPercent("5")}}</v-btn></v-col>
                                <v-col cols="2" class="text-center"><v-btn class="calc_btn" v-on:click="pressed('6')">{{appendPercent("6")}}</v-btn></v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="2" class="text-center"><v-btn class="calc_btn" v-on:click="pressedPercentage(0.75)"><b>75%</b></v-btn></v-col>
                                <v-col cols="2" class="text-center"><v-btn class="calc_btn" v-on:click="pressedPercentage(1)"><b>100%</b></v-btn></v-col>
                            </v-row> 
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-row justify="center">
                                <v-col cols="2" class="text-center"><v-btn class="calc_btn" v-on:click="pressed('7')">{{appendPercent("7")}}</v-btn></v-col>
                                <v-col cols="2" class="text-center"><v-btn class="calc_btn" v-on:click="pressed('8')">{{appendPercent("8")}}</v-btn></v-col>
                                <v-col cols="2" class="text-center"><v-btn class="calc_btn" v-on:click="pressed('9')">{{appendPercent("9")}}</v-btn></v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="4" class="text-center">
                                    <v-btn class="calc_btn" v-on:click="changeMode()" :disabled="!allowPercentageMode">
                                        Modo: <b>{{showMode}}</b>
                                    </v-btn>
                                </v-col>
                            </v-row> 
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-row justify="center">
                                <v-col cols="2" class="text-center"><v-btn class="calc_btn" v-on:click="pressed('0')">0</v-btn></v-col>
                                <v-col cols="2" class="text-center">
                                    <v-btn class="calc_btn" v-on:click="pressed('00')" :disabled="show_percentage_mode">00</v-btn>
                                </v-col>
                                <v-col cols="2" class="text-center"><v-btn class="calc_btn" v-on:click="backspace">DEL</v-btn></v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="4" class="text-center"><v-btn class="calc_btn" v-on:click="clear" color="error">Limpar</v-btn></v-col>
                            </v-row> 
                        </v-col>
                    </v-row>
                    <v-spacer vertical></v-spacer>
                    <v-row>
                        <v-col cols="12" class="text-center">
                            <v-btn block size="x-large" color="success" v-on:click="validate" 
                                :disabled="zeroBalanceComputed || zeroDiscountComputed">Validar</v-btn> 
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <DiscountCardValidation :show="show_card_validation" :discount_value="current_state.getCurrentDiscountFloat()"
        @close="onCardValidationClose" @validCard="onValidCard"></DiscountCardValidation>
    <ResultBar ref="resultBarRef" v-on:ok="onResultBarClose"></ResultBar>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, reactive, ref, useTemplateRef } from "vue";
import useClientStore from "@/scripts/core/stores/client.store";
import FiniteStateMachine from "@/scripts/core/states/state_machine";
import DiscountNumpadState from "@/scripts/modules/discount/states/discount_numpad_state";
import NumericDiscountState from "@/scripts/modules/discount/states/numeric_discount_state";
import PercentageDiscountState from "@/scripts/modules/discount/states/percentage_discount_state";
import usePreloaderStore from "@/scripts/core/stores/preloader.store";
import DiscountCardValidation from "./DiscountCardValidation.vue";
import DiscountSystem from "@/scripts/modules/systems/discount_system";

import ResultBar from "@/components/core/feedback/dialog/ResultBar.vue";

/** Variables */
const clientStore = useClientStore();
const preloaderStore = usePreloaderStore();

const validation_error_msg = ref(" ");

const numpad_state_machine = reactive(new FiniteStateMachine<DiscountNumpadState>());
let current_state: DiscountNumpadState = null;
const show_percentage_mode = ref(false);

const NUMERIC_STATE = "NUMERIC"; 
const PERCENTAGE_STATE = "PERCENTAGE"; 

const show_card_validation = ref(false);
const discount_system: DiscountSystem = new DiscountSystem();

const result_bar_ref = useTemplateRef("resultBarRef");

/** Computed */
const discountComputed = computed(() =>{ return current_state.getCurrentDiscountString(); });
const discountPercentageComputed = computed(() => { return current_state.getCurrentPercentageString(); });
const discountPercentageDiscountComputed = computed(() => { return current_state.getCurrentPercentageDiscountString(); });
const maxDiscountComputed = computed(() => { return preloaderStore.settings.maxDiscount; });
const showMode = computed(() => { return show_percentage_mode.value ? "%" : "€"; });
const allowPercentageMode = computed(() => { return clientStore.balance.realBalance * 0.01 < maxDiscountComputed.value; });
const zeroBalanceComputed = computed(() => { return clientStore.balance.realBalance <= 0; });
const zeroDiscountComputed = computed(() => { return current_state.getCurrentDiscountFloat() <= 0; });
    
/** Lifetime hooks */
onBeforeMount(() => {
   numpad_state_machine.addState(NUMERIC_STATE, new NumericDiscountState(clientStore.balance.realBalance, maxDiscountComputed.value) ); 
   numpad_state_machine.addState(PERCENTAGE_STATE, new PercentageDiscountState(clientStore.balance.realBalance, maxDiscountComputed.value) ); 
   numpad_state_machine.changeState(NUMERIC_STATE);
   current_state = numpad_state_machine.currentState;
});

/** Methods */
function inPercentageMode(): boolean { return numpad_state_machine.isCurrentState(PERCENTAGE_STATE); }
function inNumericMode(): boolean { return numpad_state_machine.isCurrentState(NUMERIC_STATE); }

function pressed(character: string)
{
    if(clientStore.balance.realBalance <= 0)
        return;

    current_state.pressed(character);
}

function pressedPercentage(raw_percentage: number)
{
    //if we do not have balance we return
    if(Number(clientStore.balance.realBalance) <= 0)
        return;
    current_state.pressedRawPercentage(raw_percentage);
}
function clear()
{
    validation_error_msg.value = " ";
    current_state.clear();
}

function backspace()
{
    current_state.backspace();
}

function validate()
{
    validation_error_msg.value = "";

    if(zeroBalanceComputed.value)
    {
        result_bar_ref.value.show(false, "Sem saldo disponível.");
        return;
    }
    if(zeroDiscountComputed.value)
    {
        result_bar_ref.value.show(false, "Valor do desconto inválido.");
        return;
    }

    //ok
    show_card_validation.value = true;

}

function changeMode()
{
    //clear current mode that will be changed
    current_state.clear();

    if(inNumericMode())
    {
        numpad_state_machine.changeState(PERCENTAGE_STATE);
        show_percentage_mode.value = true;
    }
    else
    {
        numpad_state_machine.changeState(NUMERIC_STATE);
        show_percentage_mode.value = false;
    }
    //update current state
    current_state = numpad_state_machine.currentState;
    //clear the new state, just to be safe
    current_state.clear();

}

function appendPercent(n: string)
{
    if(inPercentageMode())
        return n + "%";
    return n;
}

async function onValidCard(card: string)
{
    //apply discount
    const result = await discount_system.applyDiscount(current_state.getCurrentDiscountFloat(), card, clientStore.client.id);

    if(result.result)
    {
        //discount applied
        result_bar_ref.value.show(true, "Desconto aplicado com sucesso.");
    }
    else
    {
        //show result dialog with error
        result_bar_ref.value.show(false, result.message);
    }
    show_card_validation.value = false;
}

function onCardValidationClose()
{
    show_card_validation.value = false;
}

function onResultBarClose(is_success: boolean)
{    
    result_bar_ref.value.hide();

    if(is_success)
        current_state.clear();     
}
</script>

<style scoped>
.calc 
{
    border-bottom: thin solid #59ab2f;
    border-top: thin solid #59ab2f;
    font-size: 2em;
}

.calc_btn
{
    width: 100%;
    min-height: 64px;
}
</style>