<template>
    <FullscreenDialogBase v-model="showRef" title="2ª Via Cartão" @close="close">
        <v-row class="px-5 pt-4 mb-0" align="center">
            <SearchByNif v-if="!clientLoadedComputed"></SearchByNif>
            <ClientData v-if="clientLoadedComputed"></ClientData>
        </v-row>
    </FullscreenDialogBase>    
</template>

<script setup lang="ts">
import SearchByNif from "./SearchByNif.vue";
import ClientData from "./ClientData.vue";

import FullscreenDialogBase from '@/components/core/feedback/dialog/FullscreenDialogBase.vue';
import { toRef, computed } from "vue";
import useNewCardStore from "@/scripts/core/stores/new.card.store";

const newCardStore = useNewCardStore();

const props = defineProps({
    show: Boolean,
});

const showRef = toRef(props, "show");

const clientLoadedComputed = computed(()=> { return newCardStore.isClientLoaded; });

function close()
{
    //cleanup any information realated to the dialog
    newCardStore.clear();
}

</script>