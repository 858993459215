import axios from "axios";

//@ts-expect-error env exists but ts cant find it
axios.defaults.baseURL = import.meta.env.VITE_API_SERVER + "/terminal";

axios.interceptors.request.use(function(config)
{
    const token = localStorage.token;
    if(token)
    {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
})

const api = 
{
    //terminal auth
    login(username: string, password: string)
    {
        return new Promise(
            (resolve) => {
                axios.post("/auth/login", {"username" : username, "password":password})
                .then( response => { resolve(response) } )
                .catch( (error) => { resolve(error.response); } );
            }
        ); 
    },

    validateShift(shop_id: number, shift_number: string)
    {
        return new Promise(
            (resolve) => {
                axios.post("/auth/shift", {"shop_id":shop_id, "shift_number" : shift_number})
                .then( response => { resolve(response) } )
                .catch( (error) => { resolve(error.response); } );
            }
        ); 
    },

    loadShops()
    {
        return new Promise(
            (resolve) => {
                axios.get("/auth/shops")
                .then( response => { resolve(response) } )
                .catch( (error) => { resolve(error.response); } );
            }
        ); 
    },

    getNextShift(shop_id: number)
    {
        return new Promise(
            (resolve) => {
                axios.get("/auth/shop/"+shop_id+"/next_shift")
                .then( response => { resolve(response) } )
                .catch( (error) => { resolve(error.response); } );
            }
        ); 
    },

    validateShop(shop_id: number)
    {
        return new Promise(
            (resolve) => {
                axios.post("/auth/shop", {"shop_id":shop_id})
                .then( response => { resolve(response) } )
                .catch( (error) => { resolve(error.response); } );
            }
        ); 
    },
};

export default api;