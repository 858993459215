<template>
    <FullscreenDialogBase v-model="showRef" title="Sair">
        <v-card class="fill-height">
            <v-row class="fill-height" align="center">
                <v-col cols="12" class="text-center fill-height">
                    <v-row class="mx-0" align="center" justify="center" style="height:50%">
                        <v-col cols="12" class="text-center">
                            <v-icon size="256" id="warning_icon" >mdi-alert-octagram-outline</v-icon>
                        </v-col>
                    </v-row>
                    <v-row class="mx-0" align="center" justify="center"  style="height:25%">
                        <v-col cols="12" class="text-center">
                            <span class="text-h2">Deseja sair da aplicação?</span>
                        </v-col>
                    </v-row>
                    <v-row class="mx-0" align="center" justify="center"  style="height:5%">
                        <v-divider></v-divider>
                    </v-row>
                    <v-row class="mx-0" align="center" justify="center"  style="height:20%">
                        <v-col cols="6" class="text-center">
                            <v-btn v-on:click="emit('cancel')" color="error" x-large style="width:20%">Cancelar</v-btn>
                        </v-col>
                        <v-col cols="6" class="text-center">
                            <v-btn v-on:click="emit('ok')" color="primary" x-large style="width:20%">Sair</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
    </FullscreenDialogBase>
</template>

<script setup lang="ts">
import FullscreenDialogBase from '@/components/core/feedback/dialog/FullscreenDialogBase.vue';

import { toRef } from "vue";

const props = defineProps({
    show: Boolean,
});

const showRef = toRef(props, "show");

const emit = defineEmits(["ok", "cancel"]);
</script>