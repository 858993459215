import BaseField from "./base_field.js";

class TelFieldData<T> extends BaseField<T>
{
    country;
    number: string;
    valid: boolean;

    constructor()
    {
        super();
        this.country = "";
        this.number = "";
        this.valid = false;
    }

    validate(): boolean
    {
        if(!this.valid)
        {
            this.setErrorList(["Número não é válido"]);
            return false;
        }
        //default
        return super.validate();
    }

    validateEmpty(): boolean
    {
        if(this.value && !this.valid)
        {
            this.setErrorList(["Número não é válido"]);
            return false;
        }
        //default
        return super.validate();
    }
}

export default TelFieldData;