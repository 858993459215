<template>
    <div class="bg-shift">  
        <v-main style="height: 100%;">
            <v-form @submit.prevent style="height: 100%;">
            <v-container v-on:keyup.enter="submit" style="padding: 0; margin: 0; height: 100%;">
                <v-row class="fill-height" no-gutters >
                    <v-col cols="3" sm="6" lg="4" xl="3" class="text-left">
                        <v-sheet class="pa-8 d-flex flex-column" style="height: 100%;">

                            <v-row align="center" justify="center">
                                <v-col cols="12" class="text-center">
                                    <img src="@/assets/images/FI_TUACARD_logo.png" alt="terminal_logo" width="100%">
                                </v-col>
                            </v-row>

                            <v-row align="center" justify="center">
                                <v-col cols="12">
                                    <v-text-field variant="underlined" color="primary" autofocus
                                        :rules="shift.validationRules()" 
                                        v-model="shift.value" class="center-input-text"
                                        :error-messages="shift.error_msg" :label="shift.label">
                                    </v-text-field>
                                </v-col>
                            </v-row>

                            <v-row align="center" justify="center">
                                <v-col cols="6" class="text-center">
                                    <v-btn color="error" size="large" v-on:click="exit">Sair</v-btn>
                                </v-col>
                                <v-col cols="6" class="text-center">
                                    <v-btn color="primary" size="large" v-on:click="submit">Validar</v-btn>
                                </v-col>
                            </v-row>

                            <v-row align="end" justify="center">
                                <CopyrightFooter></CopyrightFooter>
                            </v-row>

                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
            </v-form>
        </v-main>
        <ResultBar ref="resultBarRef" v-on:ok="onResultBarClose"></ResultBar>
    </div>
</template>

<script setup lang="ts">

import TextFieldData from "@/scripts/structs/core/text_field_data";

import { reactive, onMounted, useTemplateRef } from "vue";
import AuthSystem from "@/scripts/auth/systems/auth_system";
import { useRouter } from "vue-router";
import ResultBar from "@/components/core/feedback/dialog/ResultBar.vue";
import CopyrightFooter from "./CopyrightFooter.vue";

const shift : TextFieldData = reactive(new TextFieldData());
const auth_system: AuthSystem = new AuthSystem();
const router = useRouter();
const result_bar_ref = useTemplateRef("resultBarRef");

onMounted(()=>{
    shift.label = "Número de turno";

    //add new validation rule to shift number
    const rule_is_number = (value) => 
    {
        if(value)
            return !isNaN(value) || "Valor do turno tem que ser um número";
        return false;
    }

    shift.addValidationRule(rule_is_number);
});

async function submit()
{
    clearErrors();
    if(validate())
    {
        const result = await auth_system.validateShiftNumber(shift.value);
        if(result.result)
        {
            router.replace("/terminal/search");
        }
        else//error
        {
            result_bar_ref.value.show(false, result.message);
        }                
    }
}

function exit()
{
    auth_system.logout();
}

function validate()
{
    return shift.validate();
}

function clearErrors()
{
    result_bar_ref.value.hide();
    shift.clearErrors();
}

function onResultBarClose()
{    
    result_bar_ref.value.hide();    
}   

</script>

<style scoped>
.bg-shift
{    
    background-image: url(@/assets/images/es-tuacar-mirandela-sul-img-2.jpg) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;    
    background-size: cover !important;
    height: 100%;
}
</style>