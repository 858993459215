import ShiftPopUp from "@/components/modules/shift/ShiftPopUp.vue";
import ShiftError from "@/components/core/feedback/error/ClosedShift.vue";

const routes = [
    {
        name: "shift",
        path: "/terminal/shift",
        component: ShiftPopUp
    },
    {
        name: "shift_error",
        path: "/terminal/shift/error",
        component: ShiftError
    },
];

export default routes;