<template>
    <v-row>
        <v-col cols="6">
            <v-col cols="12" class="pl-0"><span class="primary--text text-h6">{{nameComputed}}</span></v-col>    
        </v-col>
        <v-col cols="6" class="text-right">
            <v-btn size="large" outlined color="black" v-on:click="edit">Editar</v-btn>
        </v-col>

        <v-col cols="6" lg="6" sm="3" class="pb-0"><b>Nif</b>  {{nifComputed}} </v-col>
        <v-col cols="6" lg="6" sm="3" class="pb-0"><b>Tipo</b> {{typeComputed}}</v-col>
        <v-col cols="6" lg="6" sm="3" class="pb-0"><b>Género</b>  {{genderComputed}}</v-col>
        <v-col cols="6" lg="6" sm="3" class="pb-0"><b>Telemóvel</b> {{cellphoneComputed}}  </v-col>
        <v-col cols="12" ><b>Comentário</b>  {{observationsComputed}}  </v-col>
    </v-row>
                        
    <EditForm :show="show_edit_form" @close="closeEdit"></EditForm>  
</template>

<script setup lang="ts">
import Gender from "@/scripts/core/enums/gender.js"; 
import ClientType from "@/scripts/core/enums/client_type.js"; 
import { computed, ref } from "vue";
import useClientStore from "@/scripts/core/stores/client.store";
import EditForm from "./EditForm.vue";

const clientStore = useClientStore();

const nameComputed = computed(() =>{ return clientStore.personalData.name; });
const nifComputed = computed(() =>{ return clientStore.personalData.nif; });
const cellphoneComputed = computed(() =>{ return clientStore.personalData.cellphone; });
const observationsComputed = computed(() =>{ return clientStore.client.observations; });

const genderComputed = computed(() =>{ return Gender.getText(clientStore.personalData.gender); });
const typeComputed = computed(() =>{ return ClientType.getText(clientStore.client.type); });

const show_edit_form = ref(false);

function edit()
{
    show_edit_form.value = true;
}

function closeEdit()
{
    show_edit_form.value = false;
}
</script>