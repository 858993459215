<template>
    <v-row class="px-5 pt-4 mb-0" style="height: 100%;">
        <v-col cols="12"><v-divider></v-divider></v-col>
        <v-col cols="12" sm="12" lg="12">
            <v-card class="pa-6 pb-16">
                <v-row cols="12">
                    <v-col cols="6"><span class="primary--text text-h6">Últimos relatórios de turno</span></v-col>
                </v-row>
                <v-row>
                <v-col cols="12">
                    <!-- falta elevation -->
                    <v-data-table :headers="headers" :items="entries" items-per-page="5" :items-per-page-options=per_page_options>
                        <template v-slot:item.link="{ item }">
                            <v-icon v-on:click="showReport(item)">mdi-eye-outline</v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            </v-card>
        </v-col>
    </v-row>
    <ShiftReport :shift="selected_shift" :show="showReportRef" v-on:close="showReportRef = false"></ShiftReport>
</template>

<script setup lang="ts">

import ShiftReport from "./ShiftReport.vue";
import { ref, onMounted } from "vue";
import ShiftSystem from "@/scripts/modules/systems/shift_system";

const headers = [{ title: 'Turno', value: 'shift', sortable: false },
                 { title: 'Abertura', value: 'start' },
                 { title: 'Fecho', value: 'end' },
                 { title: 'Relatório', value: 'link' }];

const entries = ref([]);

const showReportRef = ref(false);
let selected_shift: number = 0;

const shift_system = new ShiftSystem();

const per_page_options = [ {value: 5, title: '5'} ];

onMounted(async ()=>
{
    const reports = await shift_system.getLatestReports();
    if(reports.result)
        processLatestResult(reports.payload);
});

function processLatestResult(reports)
{
    entries.value.splice(0);
    for(let i = 0; i<reports.length; ++i)
    {
        let item = { id: 0, shift_id: 0, shift: 0, start: "", end : "", link: ""};
        item.id = i;
        item.shift_id = reports[i].id;//not the shift_id
        item.shift = reports[i].number;//not the shift_id
        item.start = reports[i].start;
        item.end = reports[i].end;
        item.link = "shift/report/"+reports[i].id;//the shift_id

        entries.value.push(item);
    }
}

function showReport(entry)
{
    selected_shift = entry.shift_id;
    showReportRef.value = true;
}
</script>