
import router from "./core/router";
import pinia from "./core/pinia";
import vuetify from "./core/vuetify";
import "vuetify/dist/vuetify.min.css";

//cellphone plugin
import 'flag-icons/css/flag-icons.min.css';
import 'v-phone-input/dist/v-phone-input.css';
import { createVPhoneInput } from 'v-phone-input';

const vPhoneInput = createVPhoneInput({
  countryIconMode: 'svg'
});
//cellphone plugin end

import { createApp } from "vue";

import app from "@/components/app.vue";

const vue_app = createApp(app);

vue_app.use(router);
vue_app.use(pinia);
vue_app.use(vuetify);
vue_app.use(vPhoneInput);

vue_app.mount("#app");
