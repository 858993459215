<template>
    <Overlay :show="showComputed" :fullpage="true" :load="true"></Overlay>
</template>

<script setup lang="ts">


import Overlay from "@/components/core/OverlayElement.vue";
import useGlobalStore from "@/scripts/core/stores/global.store";
import { computed } from "vue";

const globalStore = useGlobalStore();
const showComputed = computed(() => { return globalStore.getShowLoading; });

</script>