class ApiClientNewData
{
    name: string;
    nif: string;
    country_code: string;
    cellphone: string;
    type: string;
    gender: string;
    
    card: string;
    user_id: number;

    source: string;
    source_shop_id: number;

    constructor(name: string, nif: string, country_code: string, cellphone: string, 
                type: string, gender: string, card: string, user_id: number, source: string, source_shop_id: number)
    {
        this.name = name;
        this.nif = nif;
        this.country_code = country_code;
        this.cellphone = cellphone;
        this.type = type;
        this.gender = gender;
        
        this.card = card;
        this.user_id = user_id;

        this.source = source;
        this.source_shop_id = source_shop_id;
    }
}

export default ApiClientNewData;