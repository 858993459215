import axios from "axios";

axios.interceptors.request.use(function(config)
{
    const token = localStorage.token;
    if(token)
    {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
})

const api = 
{    
    uploadSaft(form_data)
    {
        return new Promise(
            (resolve) => {
                axios.post("/saft/upload", form_data, {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }} )
                .then( response => { resolve(response); } )
                .catch( (error) => { resolve(error.response); } );
            }
        ); 
    }
};

export default api;