import { defineStore } from 'pinia' 
import Client from "./types/client"
import PersonalData from "./types/personal_data"
import Transactions from "./types/transactions"

interface State 
{
    client: Client,
    personalData: PersonalData,
    transactions: Transactions,
}

const actions =
{
    setClient(data)          { this.client.set(data); },
    setPersonalData(data)    { this.personalData.set(data); },
    setTransactions(data)    { this.transactions.set(data); },

    clear()
    {
        this.client.clear();
        this.personalData.clear();
        this.transactions.clear();
    }
}

const getters =
{
    isClientLoaded(): boolean { return this.client.loaded; },
}

const useNewCardStore = defineStore("new_card", {
    state: (): State => ({
        client: new Client(),
        personalData: new PersonalData(),
        transactions: new Transactions(),
      }),
    actions: actions,
    getters: getters
});

export default useNewCardStore;
/** 
 * We need to export a new type with the return type of the useCardStore, 
 * so we can correctly declare variables to store the return of the call
 */
export type NewCardStore = ReturnType<typeof useNewCardStore>