import BaseEnum from "./base_enum.js"
import BaseEnumEntry from "./base_enum_entry.js";

class TransactionTypeEnum extends BaseEnum
{
    DEBIT: BaseEnumEntry;
    CREDIT: BaseEnumEntry;
    BONUS: BaseEnumEntry;
    CORRECTION: BaseEnumEntry;
    constructor()
    {
        super();
        this.DEBIT      = { value : "debit",      text : "Débito"   };
        this.CREDIT     = { value : "credit",     text : "Crédito"  };
        this.BONUS      = { value : "bonus",      text : "Bónus"    };
        this.CORRECTION = { value : "correction", text : "Correção" };
    }
}

const TransactionType = new TransactionTypeEnum();
export default TransactionType;