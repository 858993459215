<template>
    <div>
        <UploadDialog :show="show"></UploadDialog>
    </div>
</template>

<script setup lang="ts">
import UploadDialog from "./UploadDialog.vue";

import { ref } from 'vue';

const show = ref(true);
</script>