import BaseEnumEntry from "./base_enum_entry";

class BaseEnum
{
    /**
     * Returns the text associated with the given value.
     * Returns an empty string if no match is found.
     * @param value The value to search for
     */
    getText(value: string)
    {
        for (const key in this)
        {
            if (Object.prototype.hasOwnProperty.call(this, key)) 
            {
                const entry = this[key] as BaseEnumEntry;
                if(entry.value === value)
                    return entry.text;
            }
        }
        return "";
    }

    /**
     * Returns the key of the enum entry that matches the given text.
     * Returns an empty string if no match is found.
     * @param text The text to search for
     */
    getKey(text: string)
    {
        for (const key in this)
        {
            if (Object.prototype.hasOwnProperty.call(this, key)) 
            {
                const entry = this[key] as BaseEnumEntry;
                if(entry.text === text)
                    return key;
            }
        }
        return "";
    }

    /**
     * Returns an array with all the enum entries.
     * @returns An array of enum entries
     */
    toArray()
    {
        const retval = [];
        for (const key in this)
        {
            if (Object.prototype.hasOwnProperty.call(this, key)) 
                retval.push(this[key]);
        }

        return retval;
    }
}

export default BaseEnum; 