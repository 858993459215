import { PDFDocument, PDFFont, RGB, StandardFonts } from 'pdf-lib'
import Font from './font';
import Margins from './margins';
import Page from './page';
import Spacing from './spacing';
import Cursor from './cursor';
import Alignment from './enums/alignment';

class DocumentState
{
    private m_margins: Margins = new Margins();
    private m_font: Font = new Font();
    private m_page: Page = new Page();
    private m_spacing: Spacing = new Spacing();
    private m_cursor: Cursor = new Cursor();

    /** Initialization Methods */
    async initFonts(pdfDoc: PDFDocument)
    {
        const needed_fonts = [StandardFonts.TimesRoman, StandardFonts.TimesRomanBold];
        for(const font of needed_fonts)
        {
            const loadedFont = await pdfDoc.embedFont(font);
            this.m_font.addtype(font, loadedFont);
        }
        this.m_font.selectFont(StandardFonts.TimesRoman);
    }

    /********************/
    /** Margins Methods */
    /********************/
    marginsSetMargins(top: number, bottom: number, left: number, right: number): void
    {
        this.m_margins.top = top;
        this.m_margins.bottom = bottom;
        this.m_margins.left = left;
        this.m_margins.right = right;
    }

    marginsGetMargins(): Margins { return this.m_margins; }
    marginsGetTop(): number { return this.m_margins.top; }
    marginsGetBottom(): number { return this.m_margins.bottom; }
    marginsGetLeft(): number { return this.m_margins.left; }
    marginsGetRight(): number { return this.m_margins.right; }

    marginsSetTop(top: number): void { this.m_margins.top = top; }
    marginsSetBottom(bottom: number): void { this.m_margins.bottom = bottom; }
    marginsSetLeft(left: number): void { this.m_margins.left = left; }
    marginsSetRight(right: number): void { this.m_margins.right = right; }

    /******************/
    /** Font Methods */
    /*****************/
    fontSetFont(font: StandardFonts): void
    {
        if(this.m_font.hasFont(font))
            this.m_font.selectFont(font);
        else
            throw new Error(`Font ${font} not found`);
    }

    fontSetColor(color: RGB): void { this.m_font.color = color; }
    fontSetSize(size: number): void { this.m_font.size = size; }

    fontGetFont(): PDFFont { return this.m_font.selectedFont; }
    fontGetColor(): RGB { return this.m_font.color; }
    fontGetSize(): number { return this.m_font.size; }

    
    /*****************/
    /** Page Methods */
    /*****************/
    pageGetWidth(): number { return this.m_page.width; }
    pageGetHeight(): number { return this.m_page.height; }


    /********************/
    /** Spacing Methods */
    /********************/
    spacingGetAlignment(): Alignment { return this.m_spacing.alignment; }
    spacingSetAlignment(alignment: Alignment): void 
    {        
        this.m_spacing.alignment = alignment;
        //align the cursor
        this.m_cursor.alignX(this);
    }

    spacingGetIdentation(): number { return this.m_spacing.identation; }
    spacingSetIdentation(identation: number): void { this.m_spacing.identation = identation; }
    spacingGetLineSpacing(): number { return this.m_spacing.line_spacing; }
    spacingSetLineSpacing(line_spacing: number): void { this.m_spacing.line_spacing = line_spacing; }

    /*******************/
    /** Cursor Methods */
    /*******************/
    cursorNewLine(count: number = 1): void 
    { 
        for(let i = 0; i < count; i++)
            this.m_cursor.newLine(this);
    }
    cursorToTopLeft(): void { this.m_cursor.toTopLeft(this); }
    cursorGetCursor(): Cursor { return this.m_cursor; }
    cursorGetX(): number { return this.m_cursor.get().x; }
    cursorGetY(): number { return this.m_cursor.get().y; }
    cursorToString(): string { return `Cursor: ${this.m_cursor.get().x}, ${this.m_cursor.get().y}`; }
}

export default DocumentState;