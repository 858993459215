import axios from "axios";

axios.interceptors.request.use(function(config)
{
    const token = localStorage.token;
    if(token)
    {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
})

const api = 
{    
    //{{ Search for client
    search(card: string)
    {
        return new Promise(
            (resolve) => {
                axios.get("/client/"+card)
                    .then( response => { resolve(response); } )
                    .catch( (error) => { resolve(error.response); } );
            }
        );
    },
    //}} Search for client

    //{{ Update/new Client
    updateClient(id: number, payload)
    {
        return new Promise(
            (resolve) => {
                axios.post("/client/"+id+"/edit", payload)
                .then( response => { resolve(response); } )
                .catch( (error) => { resolve(error.response); } );
            }
        ); 
    },

    newClient(payload)
    {
        return new Promise(
            (resolve) => {
                axios.post("/client/new", payload)
                .then( response => { resolve(response); } )
                .catch( (error) => { resolve(error.response); } );
            }
        ); 
    },
    //}} Update/new Client

    //{{ Discount
    applyDiscount(client_id: number, payload)
    {
        return new Promise(
            (resolve) => {
                axios.post("/discount/"+client_id, payload)
                .then( response => { resolve(response); } )
                .catch( (error) => { resolve(error.response); } );
            }
        ); 
    },
    //}} Discount

    //{{ Cards
    //check if the card bellongs to the client
    checkClientCard(card: string, client_id: number)
    {
        return new Promise(
            (resolve) => {
                axios.get("/card/check/"+card+"/"+client_id)
                // axios.get("/TerminalCheckCard", { params : card_data } )
                .then( response => { resolve(response); } )
                .catch( (error) => { resolve(error.response); } );
            }
        ); 
    },
    //check if the card being used to register a new client is valid
    checkNewClientCard(card: string)
    {
        return new Promise(
            (resolve) => {
                axios.get("/card/client/new/check/"+card)
                .then( response => { resolve(response); } )
                .catch( (error) => { resolve(error.response); } );
            }
        ); 
    },
    //}} Cards

    //{{ Validate Client
    validateEditClient(client_data)
    {
        return new Promise(
            (resolve) => {
                axios.post("/client/"+client_data.id+"/edit/validate", client_data)
                // axios.post("/TerminalValidateEditClient", client_data)
                .then( response => { resolve(response); } )
                .catch( (error) => { resolve(error.response); } );
            }
        ); 
    },

    validateNewClient(client_data)
    {
        return new Promise(
            (resolve) => {
                axios.post("/client/new/validate", client_data)
                .then( response => { resolve(response); } )
                .catch( (error) => { resolve(error.response); } );
            }
        ); 
    },
    //}} Validate Client

    
};

export default api;