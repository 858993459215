import api from "@/scripts/modules/shift/api/api"
import useAuthStore, {AuthStore} from "@/scripts/core/stores/auth.store";
import { AxiosResponse } from "axios";
import useGlobalStore, { GlobalStore } from "@/scripts/core/stores/global.store";
import ApiResult from "@/scripts/core/types/api_result";

class ShiftSystem
{
    authStore: AuthStore;
    globalStore: GlobalStore;

    constructor()
    {
        this.authStore = useAuthStore();
        this.globalStore = useGlobalStore();
    }

    async getLatestReports(): Promise<ApiResult>
    {
        this.globalStore.showLoading();

        const payload = { user_id : this.authStore.user.id, 
                        shop_id : this.authStore.shop.id,
                        report_count : 50 };

        return api.getLatest(payload).then(
            (response: AxiosResponse) =>
            {
                this.globalStore.hideLoading();

                if(response.status == 200)
                {
                    return { result: true, payload: response.data.reports };
                }       
                return { result: false };         
            }
        );
    }

    async closeShift(): Promise<ApiResult>
    {
        this.globalStore.showLoading();

        const payload = { user_id : this.authStore.user.id, 
                        shift_id : this.authStore.user.shiftId};
        
        return api.closeShift(payload).then(
            (response: AxiosResponse) =>
            {
                this.globalStore.hideLoading();

                if(response.status == 200)
                {
                    //get shift from store
                    // const shift = this.authStore.user.shift_number;
                    //remove shift from store and localstorage
                    this.authStore.closeShift();

                    return { result: true };
                }
                return { result: false };                
            }
        );
    }

    async getReport(shift: number): Promise<ApiResult>
    {
        this.globalStore.showLoading();

        const payload = { user_id : this.authStore.user.id, 
                        shift : shift };

        return api.getReport(payload).then(
            (response: AxiosResponse) =>
            {
                this.globalStore.hideLoading();

                if(response.status == 200)
                {
                    return { result: true, payload: response.data };
                }       
                return { result: false }; 
            }
        );
    }
}

export default ShiftSystem;