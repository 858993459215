import BaseField from "./base_field.js";
class FileFieldData extends BaseField<File>
{    
    constructor()
    {
        super();
    }

    setDefaultValidationRules(): void
    {
        this.rules = [
            (value) => { return !!value || "O campo não pode estar vazio"}
        ];
    }
}

export default FileFieldData;