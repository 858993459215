import api from "@/scripts/modules/client/api/api"
import preloader from "@/scripts/preloader/api/api"
import useClientStore, {ClientStore} from "@/scripts/core/stores/client.store";
import usePreloaderStore, {PreloaderStore} from "@/scripts/core/stores/preloader.store";
import { AxiosResponse } from "axios";
import useGlobalStore, { GlobalStore } from "@/scripts/core/stores/global.store";
import ApiResult from "@/scripts/core/types/api_result";

class SearchSystem
{
    clientStore: ClientStore = useClientStore();
    preloaderStore: PreloaderStore = usePreloaderStore();
    globalStore: GlobalStore = useGlobalStore();

    constructor()
    {
        //just a safeguard
        localStorage.removeItem("client");
    }

    async search(card: string): Promise<ApiResult>
    {
        this.globalStore.showLoading();
        
        return api.search(card).then( 
            (response: AxiosResponse) => { 
                
                this.globalStore.hideLoading();

                const data = response.data;
                //check data result
                if(response.status == 200)
                {
                    this.clientStore.setClient(data.client);
                    this.clientStore.setPersonalData(data.personal_data);
                    this.clientStore.setBalance(data.balance);

                    this.clientStore.setCard(data.card);
                    this.clientStore.setTransactions(data.transactions);

                    //change page
                    localStorage.client = true;

                    const id = data.client.client_id;
                    return { result: true, payload: {path: "/terminal/client/"+id} };
                }
                else if(response.status == 404)
                {
                    return { result: false, message: "Cartão inválido." };
                }
                else if(response.status == 400)
                {
                    const msg = data.message === "suspended" ? "Cliente suspenso." : "Cartão inválido";
                    return { result: false, message: msg };
                }
                               
            } 
        );
    }

    closeClient()
    {
        //clear the client data
        localStorage.removeItem("client");
        this.clientStore.clear();
    }

    async loadSettings(): Promise<ApiResult>
    {
        this.globalStore.showLoading();

        return preloader.GetSettings().then( 
            (response: AxiosResponse) => { 
                
                this.globalStore.hideLoading();

                if(response.status == 200)
                {
                    this.preloaderStore.setSettings(response.data.settings);
                    return { result: true };
                }
                else
                {
                    this.preloaderStore.unsetSettings();
                    return { result: false };
                }
                
                
            } 
        );
    }
}

export default SearchSystem;