import DiscountNumpadState from "./discount_numpad_state";

class NumericDiscountState extends DiscountNumpadState
{ 
    constructor(total_balance: number, max_discount: number)
    {
        const NUMERIC_STATE = "NUMERIC"; 
        super(NUMERIC_STATE, total_balance, max_discount);
    }

    onEnter(): void {}
    onExit(): void {}

    pressed(character: string): void
    {
        if(this.m_total_balance <= 0)
            return;

        const zero_char = "0";
        const double_zero_char = "00";
        if((character === zero_char || character === double_zero_char) 
            && this.m_current_discount_string === "0.00")
            return;

        this.m_current_discount_string += character;

        this.setDecimalSeparator();
        //check lomits
        const current_discount_float = parseFloat(this.m_current_discount_string);

        //cap to total_balance
        if(current_discount_float > this.m_total_balance)
        {
            this.m_current_discount_string = this.m_total_balance.toFixed(2);
        }

        //cap to max discount
        if(current_discount_float > this.m_max_discount)
        {
            this.m_current_discount_string = this.m_max_discount.toFixed(2);
        }
    }

    backspace(): void
    {
        this.m_current_discount_string = this.m_current_discount_string.slice(0, -1);//remove last character

        this.setDecimalSeparator();        
    }

    private setDecimalSeparator(): void
    {
        //remove all '.' characters from the string. We will handle its position manually.
        this.m_current_discount_string = this.m_current_discount_string.replace(/\./g, '');
        //remove all '0' characters from the string. We will handle its position manually, if needed.
        this.m_current_discount_string = this.m_current_discount_string.replace(/^0+/, '');
        //set the '.' position
        if(this.m_current_discount_string.length > 2)
        {
            const length = this.m_current_discount_string.length;
            this.m_current_discount_string = this.m_current_discount_string.slice(0, length - 2) + "." + this.m_current_discount_string.slice(length - 2);
        }
        else if(this.m_current_discount_string.length == 2)
            this.m_current_discount_string = "0." + this.m_current_discount_string;
        else if(this.m_current_discount_string.length == 1)
            this.m_current_discount_string = "0.0" + this.m_current_discount_string;
        else
            this.m_current_discount_string = "0.00";
    }
}

export default NumericDiscountState;