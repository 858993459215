<template>
    <v-col cols="6" sm="12" lg="6">
        <v-card class="pa-6" :disabled="!settingsLoadedComputed" style="min-height: 100%;"> 
            <v-row>
                <v-col cols="6" class="text-center">
                    <b>Saldo Disponível</b><br/>
                    € {{realBalanceComputed}}
                </v-col>
                <v-col cols="6" class="text-center">
                    <b>Desconto Máximo</b><br/>
                    € {{maxDiscountComputed}}
                </v-col>
            </v-row>

            <NumpadControl></NumpadControl>

            <v-overlay v-model="zeroBalanceComputed" class="align-center justify-center" contained >
                <v-alert><span class="text-center text-error text-h5"><b>Sem saldo disponível.</b></span></v-alert>
            </v-overlay>
        </v-card>
    </v-col>
</template>

<script setup lang="ts">
import { computed } from "vue";
import NumpadControl from "./discount/NumpadControl.vue";
import useClientStore from "@/scripts/core/stores/client.store";
import usePreloaderStore from "@/scripts/core/stores/preloader.store";

const clientStore = useClientStore();
const preloaderStore = usePreloaderStore();

const realBalanceComputed = computed(() => { return clientStore.balance.realBalance.toFixed(2); });
const maxDiscountComputed = computed(() => { return preloaderStore.settings.maxDiscount; });
const settingsLoadedComputed = computed(() => { return preloaderStore.settings.loaded; });
const zeroBalanceComputed = computed(() => { return clientStore.balance.realBalance <= 0; });

</script>