<template>
    <v-dialog v-model="showRef" max-width="50%" max-height="50%">
        <v-card class="pa-5">
            <v-row class="mx-0" align="center" justify="center">
                <v-col cols="12" class="text-center">
                    <v-icon color="success" size="128" >mdi-help-circle-outline</v-icon>
                </v-col>
            </v-row>
            <v-row class="mx-0" align="center" justify="center">
                <v-col cols="12" class="text-center">
                    <span class="text-h5">{{message}}</span>
                </v-col>
            </v-row>
            <v-row class="mx-0" align="center" justify="center">
                <v-col cols="6" class="text-center">
                    <v-btn size="large" v-on:click="cancel()" color="error">Cancelar</v-btn>
                </v-col>
                <v-col cols="6" class="text-center">
                    <v-btn size="large" v-on:click="ok()" color="primary">OK</v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">

import { toRef } from "vue";

const props = defineProps({ 
    show : Boolean,
    message : String 
});

const showRef = toRef(props, "show");

const emit = defineEmits(['ok', 'cancel']);

function ok()
{
    emit("ok");
}

function cancel()
{
    emit("cancel");
}
</script>