import api from "@/scripts/modules/client/api/api"
import useAuthStore, {AuthStore} from "@/scripts/core/stores/auth.store";
import useClientStore, {ClientStore} from "@/scripts/core/stores/client.store";
import { AxiosResponse } from "axios";
import useGlobalStore, { GlobalStore } from "@/scripts/core/stores/global.store";
import ApiResult from "@/scripts/core/types/api_result";

class DiscountSystem
{
    authStore: AuthStore;
    clientStore: ClientStore;
    globalStore: GlobalStore;

    constructor()
    {
        this.authStore = useAuthStore();
        this.clientStore = useClientStore();
        this.globalStore = useGlobalStore();
    }

    async applyDiscount(discount_value: number, card: string, client_id: number): Promise<ApiResult>
    {
        this.globalStore.showLoading();
        
        const payload = {
            "card" : card,
            "user_id" : this.authStore.user.id,
            "shift_id" : this.authStore.user.shiftId,
            "value" : discount_value,
        };

        return api.applyDiscount(client_id, payload).then(
            (response: AxiosResponse) => {

                this.globalStore.hideLoading();
                const data = response.data;
                
                if(response.status == 200)
                {
                    this.clientStore.setBalance(data.balance);
                    this.clientStore.setTransactions(data.transactions);

                    return { result: true };
                }
                else
                {                    
                    if(data.message === "invalid-value")
                        return { result: false, message: "Erro ao aplicar desconto. Valor inválido."};
                    else if(data.message === "invalid-value-bigger-than-max")
                        return { result: false, message: "Erro ao aplicar desconto. Valor maior que desconto máximo."};
                    else if(data.message === "not-enough-balance")
                        return { result: false, message: "Erro ao aplicar desconto. Saldo insuficiente."};

                    //default error message
                    return { result: false, message: "Erro ao aplicar desconto. Tente novamente." };
                }

                
            }
        );
    }
}

export default DiscountSystem;