<template>
    <v-toolbar flat>
        <v-toolbar-title><span color="primary">{{shopNameComputed}}</span></v-toolbar-title>
        <img src="@/assets/images/FI_TUACARD_logo_small.png" alt="terminal_logo" height="100%">
        <v-toolbar-title class="text-end mr-4"><span>{{nameComputed}} | {{shiftComputed}}</span></v-toolbar-title>
    </v-toolbar>
</template>

<script setup lang="ts">

import useAuthStore from "@/scripts/core/stores/auth.store";
import { computed } from "vue";

const authStore = useAuthStore();

const shopNameComputed = computed(() =>{ return authStore.shop.name; });
const nameComputed = computed(() =>{ return authStore.worker.name; });
const shiftComputed = computed(() =>{ 
    const shift = authStore.user.shiftNumber;
    return shift > 0 ? "Turno "+shift : "Turno Fechado"; 
});
</script>