import 'vuetify/styles';
import { createVuetify, type ThemeDefinition } from "vuetify";

const customLightTheme: ThemeDefinition = {
    dark: false, 
    colors: {
        primary:    "#59ab2f",
        success:    "#59ab2f",
        secondary:  "#7764e4",
        background: "#0f5e0f",
        error:      "#f53c56",
        info:       "#11cdef",
        warning:    "#fabf7b"
    }
        
};

const vuetify = createVuetify({
    theme:{
        defaultTheme: "customLightTheme",
        themes:{
            customLightTheme
        }
    }
});
export default vuetify;