import NewClientPopUp from "@/components/modules/client/NewClientPopUp.vue";
import ShowClientPopUp from "@/components/modules/client/ShowClientPopUp.vue";

const routes = [
    {
        //this route needs to be before the show_client because of the beforeEnter function
        name: "new_client",
        path: "/terminal/client/new",
        component: NewClientPopUp,
        beforeEnter: (to, from, next) => {
            next()
        }
    },
    {
        name: "show_client",
        path: "/terminal/client/:id",
        component: ShowClientPopUp,
        beforeEnter: (to, from, next) => {
            if (!localStorage.client) next({ name: 'search' })
            else next()
        }
    },    
];

export default routes;