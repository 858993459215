import { defineStore } from 'pinia' 
import Auth from "./types/auth"
import User from "./types/user"
import Worker from "./types/worker"
import Shop from "./types/shop"
import LoginData from "./types/interfaces/login_data"
import ShopData from './types/interfaces/shop_data'

interface State
{
    auth : Auth,
    user : User,
    worker : Worker,
    shop : Shop
}

const getters =
{
    isLoggedIn  : (state: State) => { return state.auth.isLoggedIn; },
    isShiftOpen : (state: State) => { return state.user.isShiftOpen(); },
}

const actions =
{
    login(data: LoginData) 
    { 
        this.auth.login(data.token, data.expiration);
        this.user.set(data.user);
        this.worker.set(data.worker);
        this.shop.set(data.shop);
    },
    logout()
    {
        this.auth.logout();
        this.user.clear();
        this.worker.clear();
        this.shop.clear();
    },

    setShop(data: ShopData)
    {
        this.shop.set(data);
    },

    openShift(shift_number: number, shift_id: number)
    {
        this.user.openShift(shift_number, shift_id);

        //override the user info in the local storeage
        const user = JSON.parse(localStorage.user);
        user.shift_number = shift_number;
        user.shift_id = shift_id;
        localStorage.user = JSON.stringify(user);

        if(user.shift_number > 0)
            localStorage.shift = user.shift_number;
    },

    closeShift()
    {
        this.user.closeShift();

        //override the user info in the local storeage
        const user = JSON.parse(localStorage.user);
        user.shift_number = 0;
        user.shift_id = 0;
        localStorage.user = JSON.stringify(user);

        localStorage.removeItem("shift");
    }
}

const useAuthStore = defineStore("auth", {
    state: (): State => ({
        auth : new Auth(),
        user : new User(),
        worker : new Worker(),
        shop : new Shop()
      }),
    getters: getters,
    actions: actions,
    persist: {
        key: "auth-store"
    },
});

export default useAuthStore;
/** 
 * We need to export a new type with the return type of the useAuthStore, 
 * so we can correctly declare variables to store the return of the call
 */
export type AuthStore = ReturnType<typeof useAuthStore>