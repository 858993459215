<template>
    <div class="bg-login">  
        <v-main style="height: 100%;">
            <v-form  @submit.prevent style="height: 100%;" :disabled="!browserSupported">
            <v-container v-on:keyup.enter="submit" style="padding: 0; margin: 0; height: 100%;">
                <v-row class="fill-height" no-gutters >
                    <v-col cols="3" sm="6" lg="4" xl="3" class="text-left">
                        <v-sheet class="pa-8 d-flex flex-column" style="height: 100%;">
                            
                            <v-row align="center" justify="center">
                                <v-col cols="12" class="text-center">
                                    <img src="@/assets/images/FI_TUACARD_logo.png" alt="terminal_logo" width="100%">
                                </v-col>
                            </v-row>

                            <v-row align="center" justify="center" v-if="!browserSupported">
                                <v-col cols="12">
                                    <v-alert type="error" >Navegador incompatível. Por favor, use um navegador mais recente.</v-alert>
                                </v-col>
                            </v-row>

                            <v-row align="center" justify="center">
                                <v-col cols="12">
                                    <v-text-field variant="underlined" :rules="username.validationRules()" 
                                        v-model="username.value" autofocus class="center-input-text"
                                        :error-messages="username.error_msg" :label="username.label" color="primary">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center">
                                <v-col cols="12">
                                    <v-text-field variant="underlined" :rules="password.validationRules()" 
                                        v-model="password.value" class="center-input-text"
                                        :error-messages="password.error_msg" :label="password.label" color="primary" 
                                        :type="password.show ? 'text' : 'password'"
                                        :append-inner-icon="password.show ? 'mdi-eye' : 'mdi-eye-off'" 
                                        v-on:click:append-inner="password.show = !password.show">
                                    </v-text-field>
                                </v-col>
                            </v-row>

                            <v-row align="center" justify="center">
                                <v-col cols="12" class="text-center">
                                    <v-btn color="primary" size="large" v-on:click="submit" :disabled="!browserSupported">Entrar</v-btn>
                                </v-col>
                            </v-row>

                            <v-row align="end" justify="center">
                                <CopyrightFooter></CopyrightFooter>
                            </v-row>
        
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
            </v-form>
        </v-main>
        <ResultBar ref="resultBarRef" v-on:ok="onResultBarClose"></ResultBar>
    </div>
</template>

<script setup lang="ts">
import TextFieldData from "@/scripts/structs/core/text_field_data";
import PasswordFieldData from "@/scripts/structs/core/password_field_data";
import { computed, reactive, useTemplateRef } from "vue";
import AuthSystem from "@/scripts/auth/systems/auth_system";
import { useRouter } from "vue-router";
import ResultBar from "@/components/core/feedback/dialog/ResultBar.vue";
import CopyrightFooter from "./CopyrightFooter.vue";

const username : TextFieldData = reactive(new TextFieldData());
const password : PasswordFieldData = reactive(new PasswordFieldData());
const auth_system: AuthSystem = new AuthSystem();
const router = useRouter();

const result_bar_ref = useTemplateRef("resultBarRef");

document.body.className = "authentication-bg authentication-bg-pattern"

username.label = "Utilizador";
password.label = "Palavra Passe";

const browserSupported = computed(() => { return checkBrowserES2015Support(); });

async function submit()
{
    clearErrors();
    if(validate())
    {
        const result = await auth_system.login(username.value, password.value);
        if(result.result)
        {
            router.replace(result.payload.path as string);
        }
        else//error
        {
            result_bar_ref.value.show(false, result.message);
        }
    }
}

function validate()
{
    //these two vars are used instead of the inline && to avoid "if elision" (if the username is false the passworld will not be checked)
    const u = username.validate();
    const p = password.validate();
    return u && p;
}

function clearErrors()
{
    result_bar_ref.value.hide();
    username.clearErrors();
    password.clearErrors();
}

function onResultBarClose()
{    
    result_bar_ref.value.hide();    
}   

function checkBrowserES2015Support()
{
    if (typeof Symbol === 'function' && typeof Map === 'function' && typeof Set === 'function') 
        return true;
    else
        return false;
}
</script>

<style>
.bg-login
{    
    background-image: url(@/assets/images/es-tuacar-mirandela-sul-img-2.jpg) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;    
    background-size: cover !important;
    height: 100%;
}
</style>