class GlobalState
{
    private m_show_loading: boolean;
    private m_show_loading_counter: number;

    constructor()
    {
        this.m_show_loading = false;
        this.m_show_loading_counter = 0;
    }

    get showLoading(): boolean { return this.m_show_loading; }
    set showLoading(show: boolean) { this.m_show_loading = show; }

    get showLoadingCounter(): number { return this.m_show_loading_counter; }
    set showLoadingCounter(count: number) { this.m_show_loading_counter = count; }

    incrementShowLoadingCounter(): void { this.m_show_loading_counter++; }
    decrementShowLoadingCounter(): void { Math.max(0, this.m_show_loading_counter--); }
}

export default GlobalState;