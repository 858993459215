class ResultBarData
{
    success: boolean;
    message: string;
    show: boolean;
    timeout: number;
    constructor()
    {
        this.success = true;
        this.message = "";
        this.show = false;
        this.timeout = 10000//for no timeout use -1
    }

    copy(other: ResultBarData)
    {
        this.success = other.success;
        this.message = other.message;
        this.show = other.show;
        this.timeout = other.timeout;
    }
}

export default ResultBarData;