import BaseEnum from "./base_enum.js"
import BaseEnumEntry from "./base_enum_entry.js";

class ClientTypeEnum extends BaseEnum
{
    CASH: BaseEnumEntry;
    CREDIT: BaseEnumEntry;
    constructor()
    {
        super();
        this.CASH    = { value : "cash", text : "Venda a dinheiro" };
        this.CREDIT  = { value : "credit", text : "Crédito local" };
    }
}

const ClientType = new ClientTypeEnum();
export default ClientType;