<template>
    <v-btn @click="onClick">
        <v-icon size="x-large" color="background">{{ icon }}</v-icon>
        <b>{{ label }}</b> 
    </v-btn>        
</template>

<script setup lang="ts">

import { toRef } from "vue";
import { useRouter } from "vue-router";

const props = defineProps({
  to: String,
  icon: String,
  label: String,
  img: String
});

const to = toRef(props, "to");
const icon = toRef(props, "icon");
const label = toRef(props, "label");

const router = useRouter();

function onClick()
{
    router.replace(to.value);
}

</script>

<style scoped>
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 50%;

  transform: translate(-50%, 0);
}
</style>