import { RGB, rgb } from "pdf-lib";

/** Although not an enum, it is used like one */
class Colors
{   
    static readonly BLACK:      RGB = rgb(0, 0, 0);
    static readonly WHITE:      RGB = rgb(1, 1, 1);
    static readonly PRIMARY:    RGB = rgb(89/255, 171/255, 47/255);
    static readonly BACKGROUND:    RGB = rgb(89/255, 171/255, 47/255);
    
}

export default Colors;