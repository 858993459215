import LayoutRoutes from  "@/scripts/layout/routes/routes";
import ModulesRoutes from  "@/scripts/modules/routes";
import AuthRoutes from  "@/scripts/auth/routes/routes";

import { createMemoryHistory, createRouter } from 'vue-router';

const auth_routes = AuthRoutes.GetRoutes();

const layout_routes = LayoutRoutes.GetRoutes();
const modules_routes = ModulesRoutes.GetRoutes();

layout_routes[0].children = [];
layout_routes[0].children = layout_routes[0].children.concat(modules_routes);

const routes = layout_routes.concat(auth_routes);

const router = createRouter({
    history: createMemoryHistory(),
    routes
});

export default router;