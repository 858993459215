import { defineStore } from 'pinia' 
import GlobalState from "./types/global"

interface State 
{
    state : GlobalState,
}

const actions =
{
    showLoading() { this.state.incrementShowLoadingCounter(); this.state.showLoading = true; },
    hideLoading() { this.state.decrementShowLoadingCounter(); if(this.state.showLoadingCounter <= 0) this.state.showLoading = false; },
} 

const getters =
{
    getShowLoading(): boolean { return this.state.showLoading; },
    getCardValidationType(): string { return this.card_validation.validationType; },
    getShowResultDialog(): boolean { return this.result_dialog.show; }
}

const useGlobalStore = defineStore("global", {
    state: (): State => ({
        state : new GlobalState(),
      }),
    actions: actions,
    getters: getters
});

export default useGlobalStore;
/** 
 * We need to export a new type with the return type of the useGlobalStore, 
 * so we can correctly declare variables to store the return of the call
 */
export type GlobalStore = ReturnType<typeof useGlobalStore>