import api from "@/scripts/modules/client/api/api"
import useClientStore, {ClientStore} from "@/scripts/core/stores/client.store";
import { AxiosResponse } from "axios";
import useGlobalStore, { GlobalStore } from "@/scripts/core/stores/global.store";
import ApiResult from "@/scripts/core/types/api_result";
import ApiClientNewData from "@/scripts/structs/api/client_new_data";

class NewClientSystem
{
    clientStore: ClientStore = useClientStore();
    globalStore: GlobalStore = useGlobalStore();

    async validateClientData(client_data: ApiClientNewData): Promise<ApiResult>
    {        
        this.globalStore.showLoading();

        return api.validateNewClient(client_data).then(
            (response: AxiosResponse) => 
            {
                this.globalStore.hideLoading();

                if(response.status == 200)
                {
                    return { result: true };    
                }
                else
                {
                    const payload = {errors: response.data.errors.fields}
                    return { result: false, payload: payload }; 
                }
            }
        );
    }

    async newClient(client_data: ApiClientNewData): Promise<ApiResult>
    {
        this.globalStore.showLoading();

        return api.newClient(client_data).then(
            (response: AxiosResponse) => 
            {
                this.globalStore.hideLoading();
                
                if(response.status == 200)
                {
                    //the redirect is now in the hands of a call to the search endpoint so we no longer store the client
                    return { result: true };
                }
                else
                {
                    return { result:false, message:"Erro ao criar o cliente. Tente novamente." };
                }
            }
        );
    }
}

export default NewClientSystem;