<template>
    <v-col cols="6" sm="12" lg="6">
        <v-card class="pa-6" style="min-height: 100%;">            
            <ShowData></ShowData>               
            <v-row>
                <v-col cols="12">
                    <span class="primary--text text-h6">MOVIMENTOS</span>
                    <v-data-table
                        :headers="headers"
                        :items="entriesComputed"
                        :items-per-page="5"
                        class="elevation-1"
                        :items-per-page-options=[5]
                    ></v-data-table>
                </v-col>
            </v-row>
        </v-card>
    </v-col>
</template>

<script setup lang="ts">
import ShowData from "./personal_data/ShowData.vue";
import TransactionType from "@/scripts/core/enums/transaction_type.js"; 
import TransactionSubType from "@/scripts/core/enums/transaction_subtype.js"; 

import useClientStore from "@/scripts/core/stores/client.store";
import { computed } from "vue";

const clientStore = useClientStore();

const headers = [{ title: 'Tipo', sortable: false, value: 'type' },
                { title: 'Sub-Tipo', sortable: false, value: 'sub_type' },
                { title: 'Valor', value: 'value' },
                { title: 'Data', value: 'date' },];

const entriesComputed = computed(() => {
    let transactions = clientStore.transactions.list;
    const entries = [];

    for(var i=0; i<transactions.length; ++i)
    {
        var transaction = { id:0, type: "", sub_type: "", value: 0, date: "", invoice_date:""};
        transaction.id = i;
        transaction.type = TransactionType.getText(transactions[i].type);
        transaction.sub_type = TransactionSubType.getText(transactions[i].sub_type);
        transaction.value = transactions[i].value;

        if(transactions[i].sub_type == "bonus")
            transaction.date = transactions[i].invoice_date;
        else
            transaction.date = transactions[i].date;

        entries.push(transaction);
    }

    return entries;
});
</script>