import useAuthStore from "@/scripts/core/stores/auth.store";
import useGlobalStore from "@/scripts/core/stores/global.store";
import ApiResult from "@/scripts/core/types/api_result";
import api from "@/scripts/modules/saft/api/api"
import { AxiosResponse } from "axios";

class SaftSystem
{
    private authStore = useAuthStore();
    private globalStore = useGlobalStore();

    async uploadSaft(file): Promise<ApiResult>
    {
        this.globalStore.showLoading(); 

        const form_data = new FormData();
        form_data.append("saft", file);
        form_data.append("user_id", this.authStore.user.id.toString());
        form_data.append("shop_id", this.authStore.shop.id.toString());

        return api.uploadSaft(form_data).then(
            (response: AxiosResponse) => {
                this.globalStore.hideLoading();
                return { result: response.status == 200 };
            }
        );
        
    }
}

export default SaftSystem;