import PersonalDataData from "./interfaces/personal_data_data";

class PersonalData
{
    private m_id: number;
    private m_client_id: number;
    private m_name: string;
    private m_gender: string;
    private m_nif: string;
    private m_country_code: string;
    private m_cellphone: string;

    constructor()
    {
        this.m_id = 0;
        this.m_client_id = 0;
        this.m_name = "";
        this.m_gender = "";
        this.m_nif = "";
        this.m_country_code = "";
        this.m_cellphone = "";
    }

    get id(): number { return this.m_id; }
    get clientId(): number { return this.m_client_id; }
    get name(): string { return this.m_name; }
    get gender(): string { return this.m_gender; }
    get nif(): string { return this.m_nif; }
    get countryCode(): string { return this.m_country_code; }
    get cellphone(): string { return this.m_cellphone; }

    set(data: PersonalDataData): void
    {
        this.m_client_id          = data.client_id;
        this.m_id   = data.personal_data_id;
        this.m_name               = data.name;
        this.m_gender             = data.gender;
        this.m_nif                = data.nif;
        this.m_country_code       = data.country_code;
        this.m_cellphone          = data.cellphone;
    }

    clear(): void
    {
        this.m_client_id = 0;
        this.m_id = 0;
        this.m_name = "";
        this.m_gender = "";
        this.m_nif = "";
        this.m_country_code = "";
        this.m_cellphone = "";
    }
}

export default PersonalData;