import BaseField from "./base_field.js";

class TextFieldData extends BaseField<string>
{
    constructor()
    {
        super();
        this.value = "";
    }

    clearValue(): void
    {
        this.value = "";
    }

    validateCardStringFormat(): void
    {
        const cardRegex = /^TUACARD\d{8}/;
        this.addValidationRule((value) =>
            cardRegex.test(value) || "Padrão inválido"
        );
    }
}

export default TextFieldData;