<template>
    <FullscreenDialogBase v-model="showRef" title="" :disable_close="true">
        <v-row class="" align="center">
            <v-col cols="12" class="text-center">
                <v-row class="mx-0" align="center" justify="center" style="height:50%">
                    <v-col cols="12" class="text-center">
                        <v-icon size="256" id="warning_icon" >mdi-alert-octagram-outline</v-icon>
                    </v-col>
                </v-row>
                <v-row class="mx-0" align="center" justify="center"  style="height:25%">
                    <v-col cols="12" class="text-center">
                        <span class="text-h3">Atenção, deve aplicar o desconto na fatura depois de o confirmar aqui!</span>
                    </v-col>
                </v-row>
                <v-row class="mx-0" align="center" justify="center"  style="height:5%">
                    <v-divider></v-divider>
                </v-row>
            </v-col>
        </v-row>
        <v-row align="center">
            <v-col class="text-center text-h5"><span><u>Passe o Cartão para validar o desconto de <b>€{{ discount_value }}</b></u></span></v-col>
        </v-row>

        <v-row align="center" no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="8" lg="6" class="text-center">
                <CardInput @valid-card="onValidCard" :has-error="card_has_error" 
                        @clear-errors="card_has_error = false" variant="filled" autofocus
                        base-color="undefined" color="primary"></CardInput>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>

        <v-row align="center" >
            <v-col cols="6" class="text-center">
                <v-btn size="large" color="error" v-on:click="close"><v-icon class="mr-2">mdi-arrow-left</v-icon>Voltar</v-btn>
            </v-col>
            <v-col cols="6" class="text-center">
                <v-btn size="large" color="primary" v-on:click="submit" :disabled="lock_btn"><v-icon class="mr-2">mdi-content-save-outline</v-icon>Validar</v-btn>
            </v-col>
        </v-row>
        <ResultBar ref="resultBarRef" v-on:ok="onResultBarClose"></ResultBar>
    </FullscreenDialogBase>
</template>

<script setup lang="ts">

import FullscreenDialogBase from '@/components/core/feedback/dialog/FullscreenDialogBase.vue';

import TextFieldData from '@/scripts/structs/core/text_field_data';
import { reactive, ref, toRef, useTemplateRef, watch } from 'vue';

import useClientStore from '@/scripts/core/stores/client.store';
import CardSystem from '@/scripts/modules/systems/card_system';

import ResultBar from "@/components/core/feedback/dialog/ResultBar.vue";
import CardInput from "@/components/core/input/CardInput.vue";

/** Variables */
const props = defineProps({
    show: Boolean,
    discount_value: { type: Number, default: 0 },
});

const showRef = toRef(props, "show");
const emit = defineEmits(["validCard", "close"]);

const clientStore = useClientStore();

const card : TextFieldData = reactive(new TextFieldData());
const card_has_error = ref(false);
const card_system: CardSystem = new CardSystem();
const lock_btn = ref(true);

const result_bar_ref = useTemplateRef("resultBarRef");


/** Watchers */
watch(showRef, () => {
    if(showRef.value)
        card.clearValueAndErrors();
})

/** Methods */
async function submit()
{
    card.clearErrors();
    card_has_error.value = false;//clear previous errors

    if(card.validate())
    {
        const result = await card_system.validateClientCard(card.value, clientStore.client.id);

        if(result.result)
        {
            emit("validCard", card.value);
        }
        else
        {
            result_bar_ref.value.show(false, "Cartão inválido.");
            card_has_error.value = true;
        }
    }
}

function close()
{
    emit("close");
}

async function onValidCard(card_str: string)
{
    card.clearErrors();
    card.value = card_str;
    //check card string structure
    submit();
    lock_btn.value = false;
}

function onResultBarClose()
{
    result_bar_ref.value.hide();
}

</script>

<style scoped>
/* The animation code */
@keyframes blink {
  0% {color: red;}
  50% {color: yellow;}
  100% {color: red;}
}

#warning_icon
{
    animation: blink 0.5s linear infinite;
}
</style>