import { PDFFont, RGB, StandardFonts } from 'pdf-lib'
import Defaults from './defaults';

class Font
{
    /** Variables */
    private m_size: number = Defaults.font.size;
    private m_color: RGB = Defaults.font.color;
    private m_selected_font: PDFFont = null;
    private m_available_fonts: Map<StandardFonts, PDFFont> = new Map<StandardFonts, PDFFont>();

    /** Getters and Setters */
    get size(): number { return this.m_size; }
    set size(size: number) { this.m_size = size; }

    get selectedFont(): PDFFont { return this.m_selected_font; }

    get color(): RGB { return this.m_color; }
    set color(color: RGB) { this.m_color = color; }

    /** Methods */
    addtype(font_name: StandardFonts, font: PDFFont, select_font: boolean = false) 
    {
        this.m_available_fonts.set(font_name, font);

        if(select_font)
            this.m_selected_font = font;
    }

    getType(font_name: StandardFonts): PDFFont { return this.m_available_fonts.get(font_name); }

    selectFont(font_name: StandardFonts) { this.m_selected_font = this.m_available_fonts.get(font_name); }

    hasFont(font_name: StandardFonts): boolean { return this.m_available_fonts.has(font_name); }
}

export default Font;