class ApiClientEditData
{
    id: number;
    personal_data_id: number;
    name: string;
    nif: string;
    country_code: string;
    cellphone: string;
    type: string;
    gender: string;
    
    card: string;
    user_id: number;

    constructor(id: number, personal_data_id: number, name: string, nif: string, country_code: string, 
            cellphone: string, type: string, gender: string, card: string, user_id: number)
    {
        this.id = id;
        this.personal_data_id = personal_data_id;
        this.name = name;
        this.nif = nif;
        this.country_code = country_code;
        this.cellphone = cellphone;
        this.type = type;
        this.gender = gender;
        
        this.card = card;
        this.user_id = user_id;
    }
}

export default ApiClientEditData;