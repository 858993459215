<template>
    <FullscreenDialogBase v-model="showRef" title="Editar Cliente" :redirect="false" @close="emit('close')">
        <v-row class="px-5 pt-4 mb-0" align="center">
            <v-col cols="6" offset="3" lg="6" offset-lg="3" sm="12" offset-sm="0" class="text-center">
                <v-row>
                    <v-col cols="12">
                        <span class="text-h5">Editar Dado Cliente</span>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field :rules="name.validationRules()" 
                            v-model="name.value"
                            :error-messages="name.error_msg" :error="name.error"
                            :label="name.label" color="primary">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-phone-input
                            defaultCountry="PT"
                            :rules="cellphone.validationRules()" 
                            v-model="cellphone.value"
                            v-model:country="cellphone.country"
                            :error-messages="cellphone.error_msg" :error="cellphone.error"
                            :label="cellphone.label" color="primary"
                            placeholder="" clearable />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field :rules="nif.validationRules()" 
                            v-model="nif.value"
                            :error-messages="nif.error_msg" :error="nif.error"
                            :label="nif.label" color="primary" disabled>
                        </v-text-field>
                    </v-col>

                    <v-col cols="6">
                        <v-select :items="type.items" v-model="type.value" :label="type.label"></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-select :items="gender.items" v-model="gender.value" :label="gender.label"></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col cols="6">
                        <v-btn size="large" color="secondary" rounded v-on:click="resetForm" :disabled="block_submit_btns">
                            <v-icon class="mr-2">mdi-sync</v-icon> Reset</v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn size="large" color="primary" rounded v-on:click="apiValidateClient" :disabled="block_submit_btns">
                            <v-icon class="mr-2">mdi-check-outline</v-icon> Validar</v-btn>
                    </v-col>
                </v-row>
            </v-col>            
        </v-row>        
        <ResultBar ref="resultBarRef" v-on:ok="onResultBarClose"></ResultBar>
    </FullscreenDialogBase>
</template>

<script setup lang="ts">

import FullscreenDialogBase from "@/components/core/feedback/dialog/FullscreenDialogBase.vue";

import TextFieldData from "@/scripts/structs/core/text_field_data";
import TelFieldData from "@/scripts/structs/core/tel_field_data";
import SelectFieldData from "@/scripts/structs/core/select_field_data";
import ApiClientEditData from "@/scripts/structs/api/client_edit_data";

import Gender from "@/scripts/core/enums/gender.js"; 
import ClientType from "@/scripts/core/enums/client_type.js"; 
import { countries, makePhone } from "v-phone-input";

import {reactive, onBeforeMount, toRef, watch, useTemplateRef, ref} from "vue";
import useClientStore from "@/scripts/core/stores/client.store";
import useAuthStore from "@/scripts/core/stores/auth.store";
import EditFormSystem from "@/scripts/modules/systems/edit_form_system";

import ResultBar from "@/components/core/feedback/dialog/ResultBar.vue";

/** Variables */
const props = defineProps({
    show: Boolean,
});

const showRef = toRef(props, "show");

const name : TextFieldData = reactive(new TextFieldData());
const nif : TextFieldData = reactive(new TextFieldData());
const cellphone : TelFieldData<string> = reactive(new TelFieldData());

const type : SelectFieldData<string> = reactive(new SelectFieldData());
const gender : SelectFieldData<string> = reactive(new SelectFieldData());

const clientStore = useClientStore();
const authStore = useAuthStore();
const edit_form_system: EditFormSystem = new EditFormSystem();

const result_bar_ref = useTemplateRef("resultBarRef");

const emit = defineEmits(["close"]);
const block_submit_btns = ref(false);

/** Watchers */
watch(showRef,async () => {
    if(showRef.value)
        resetForm();
})

/** Lifetime hooks */
onBeforeMount(() =>
{
    name.label = "Nome*";
    cellphone.label = "Telemóvel*";
    nif.label = "Nif*";

    //create selects
    //fill selects
    gender.label="Género*";
    let genders = Gender.toArray();        
    for(let key in genders)
    {
        gender.addItem(genders[key].value, genders[key].text);
    }
    gender.setDefault();

    type.label="Tipo*";
    let types = ClientType.toArray();        
    for(let key in types)
    {
        type.addItem(types[key].value, types[key].text);
    }
    type.setDefault();
});

/** Methods */
async function apiValidateClient()
{
    clearErrors();
    if(validateForm())
    {
        const country_code = countries.find((c) => c.iso2 === cellphone.country)?.dialCode;
        //NOTE: the card value does nt exist at this time, but it is not needed for this validation
        const client_data = new ApiClientEditData( clientStore.client.id, clientStore.personalData.id, name.value,
            nif.value, country_code, cellphone.value, 
            type.value, gender.value, "", authStore.user.id);

        const result = await edit_form_system.validateClientEditData(client_data);
        if(result.result)
        {
            //need to append the card
            client_data.card = clientStore.card.card;
            //update client data
            const update_result = await edit_form_system.updateClient(client_data);
            if(update_result.result)
            {
                result_bar_ref.value.show(true, "Informação do cliente atualizada com sucesso.");
                block_submit_btns.value = true;
            }
            else
            {
                result_bar_ref.value.show(false, "Ocorreu um erro ao atualizar a informação do cliente. Tente novamente.");
                block_submit_btns.value = false;
            }
        }
        else
        {
            showServerValidationErrors(result.payload.errors);
        }
    }
}

function resetForm()
{
    clearErrors();
    name.value = clientStore.personalData.name;
    nif.value = clientStore.personalData.nif;
    cellphone.value = clientStore.personalData.cellphone;

    type.setValue(clientStore.client.type.toString());
    gender.setValue(clientStore.personalData.gender.toString());

    block_submit_btns.value = false;
}

function validateForm()
{
    //this is to avoid bool optimization because all CheckEmptyInput need to run as they can set an error
    cellphone.valid = makePhone(cellphone.value).valid;
    //this is to avoid bool optimization because all CheckEmptyInput need to run as they can set an error
    const check = [ name.validate(), nif.validate(), cellphone.validate(),
                    (type.value !== ""), (gender.value !== "") ];
    
    let result = true;
    for(let i=0; i<check.length; ++i)
        result = result && check[i];
    return result;
}

function clearErrors()
{
    name.clearErrors();
    nif.clearErrors();
    cellphone.clearErrors();
    // country_code.clearErrors();
    type.clearErrors();
    gender.clearErrors();
}

function showServerValidationErrors(data)
{
    data.forEach(element => 
    {
        switch(element.field)
        {
            case "name": name.setErrorList(element.errors); break;

            case "nif": nif.setErrorList(element.errors); break;
            case "cellphone": cellphone.setErrorList(element.errors); break;

            case "type": type.setErrorList(element.errors); break;
            case "gender": gender.setErrorList(element.errors); break;
        }
    });
}

function onResultBarClose(is_success: boolean)
{
    result_bar_ref.value.hide();
    if(is_success)
    {
        emit("close");
    }       
}
</script>