<template>
    <FullscreenDialogBase v-model="showRef" title="Importar Ficheiro SAFT">
        <v-row class="px-5 pt-4 mb-0" align="center">
            <v-col cols="12">
                <v-row align="center">
                    <v-col cols="6" offset="3" class="text-center"><span class="primary--text text-h6">Selecionar ficheiro SAFT</span></v-col>
                </v-row>

                <v-row align="center">
                    <v-col cols="6" offset="3">
                        <v-file-input show-size 
                            v-model="saft_field.value"
                            :label="saft_field.label"  accept=".xml"
                            :error-messages="saft_field.error_msg" color="primary" ref="saft" clearable>
                        </v-file-input>
                    </v-col>
                </v-row>

                <v-row align="center">
                    <v-col cols="6" offset="3" class="text-center">
                        <v-btn size="large" color="primary" v-on:click="submit" rounded><v-icon class="mr-2">mdi-content-save-outline</v-icon> Guardar</v-btn>
                    </v-col>
                </v-row>
                <ResultBar ref="resultBarRef" v-on:ok="onResultBarClose"></ResultBar>
            </v-col>
        </v-row>
    </FullscreenDialogBase>
</template>

<script setup lang="ts">
import FileFieldData from "@/scripts/structs/core/file_field_data";

import FullscreenDialogBase from '@/components/core/feedback/dialog/FullscreenDialogBase.vue';
import { onBeforeMount, reactive, toRef, useTemplateRef } from "vue";
import SaftSystem from "@/scripts/modules/systems/saft_system";

import ResultBar from "@/components/core/feedback/dialog/ResultBar.vue";

const props = defineProps({
    show: Boolean,
});

const showRef = toRef(props, "show");

const saft_field = reactive(new FileFieldData());
const saft_system = new SaftSystem();

const result_bar_ref = useTemplateRef("resultBarRef");

/** Lifetime hooks */
onBeforeMount(() =>{

    saft_field.label = "Selecionar ficheiro SAFT...";
});

/** Methods */
async function submit()
{
    if(saft_field.validate())
    {
        const result = await saft_system.uploadSaft(saft_field.value);

        if(result.result)
        {
            result_bar_ref.value.show(true, "Ficheiro importado com sucesso.");
            saft_field.clearValueAndErrors();
        }
        else
        {       
            result_bar_ref.value.show(false, "Erro ao importar o ficheiro SAFT. Tente novamente."); 
        }
    }
}

function onResultBarClose()
{
    result_bar_ref.value.hide();
}
</script>