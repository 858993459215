import BalanceData from "./interfaces/balance_data";

class Balance
{
    private m_client_id: number;
    private m_accounting_balance: number;
    private m_real_balance: number;

    constructor()
    {
        this.m_client_id = 0;
        this.m_accounting_balance = 0;
        this.m_real_balance = 0;
    }

    get clientId(): number { return this.m_client_id; }
    get accountingBalance(): number { return this.m_accounting_balance; }
    get realBalance(): number { return this.m_real_balance; }

    set(data: BalanceData): void
    {
        this.m_client_id = data.client_id;
        this.m_accounting_balance = data.accounting_balance;
        this.m_real_balance = data.real_balance;
    }

    clear(): void
    {
        this.m_client_id = 0;
        this.m_accounting_balance = 0;
        this.m_real_balance = 0;
    }
}

export default Balance;
