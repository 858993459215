import Terminal from "@/components/layout/TerminalView.vue";

const routes = {
    GetRoutes()
    {
        let routes = [];
        routes = routes.concat(this.paths);
        
        return routes;
    },

    paths:[
        {
            path: "/terminal",
            alias: "/terminal/*",
            name: "terminal",
            meta: {basePath: '/terminal'},
            component: Terminal,
            beforeEnter: (to, from, next) => {
                if (!localStorage.token) next({ name: 'login' })
                else next()
            }
        }
    ]
};

export default routes;