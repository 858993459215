import BaseState from "@/scripts/core/states/base_state";

abstract class DiscountNumpadState extends BaseState
{
    /** The real balance of the account */
    /** THIS VARIBALES IS PUBLIC BECAUSE OF THE WAY TYPESCRIPT HANDLES POLYMORPHISM */
    public m_total_balance: number = 0;
    /** THIS VARIBALES IS PUBLIC BECAUSE OF THE WAY TYPESCRIPT HANDLES POLYMORPHISM */
    public m_max_discount: number = 0;
    /** THIS VARIBALES IS PUBLIC BECAUSE OF THE WAY TYPESCRIPT HANDLES POLYMORPHISM */
    public readonly m_max_discount_percentage: number = 0;

    /** THIS VARIBALES IS PUBLIC BECAUSE OF THE WAY TYPESCRIPT HANDLES POLYMORPHISM */
    public m_current_discount_string: string = "0.00";
    /** THIS VARIBALES IS PUBLIC BECAUSE OF THE WAY TYPESCRIPT HANDLES POLYMORPHISM */
    public m_current_percentage_string: string = "000";

    constructor(name: string, total_balance: number, max_discount: number)
    {
        super(name);
        this.m_total_balance = total_balance;
        this.m_max_discount = max_discount;

        //to avoid dividing by 0
        this.m_max_discount_percentage = total_balance > 0 ? max_discount / total_balance : 0;
    }

    abstract pressed(character: string): void;   
    abstract backspace(): void;

    /**
     * @param raw_percentge Value between 0 and 1
     */
    pressedRawPercentage(raw_percentage: number): void
    {
        if(this.m_total_balance <= 0)
            return;
        
        raw_percentage = Math.min(raw_percentage, this.m_max_discount_percentage);//clamp max value
        raw_percentage = Math.min(raw_percentage, 1);//clamp to 100% if needed

        this.m_current_percentage_string = Math.round(raw_percentage * 100).toString();

        if(raw_percentage < 0.01)//prepend "~" if percentage is less than 1%
            this.m_current_percentage_string = "~" + this.m_current_percentage_string;

        //round to 2 decimals
        this.m_current_discount_string = (Math.round((this.m_total_balance * raw_percentage) * 100) / 100).toFixed(2);

    }

    public clear(): void
    {
        this.m_current_discount_string = "0.00";
        this.m_current_percentage_string = "000";
    }

    public getCurrentPercentageString(): string { return this.m_current_percentage_string; }
    public getCurrentPercentageDiscountString(): string 
    {
        const percentage_string = this.m_current_percentage_string .replace(/^~+/, '');
        const percentage = parseFloat(percentage_string);
        
        return Math.min(this.m_total_balance * percentage / 100, this.m_max_discount).toFixed(2);
    }

    public getCurrentDiscountString(): string { return this.m_current_discount_string; }
    public getCurrentDiscountFloat(): number { return parseFloat(this.m_current_discount_string); }
}

export default DiscountNumpadState;