class Transaction
{
    id: number;
    value: number;
    user:string;
    date: string;
    type: string;
    sub_type: string;
    observation: string;
    invoice_date: string;

    constructor()
    {
        this.id = 0;
        this.value = 0;
        this.user = "";
        this.date = "";
        this.type = "";
        this.sub_type = "";
        this.observation = "";
        this.invoice_date = "";
    }
}

class Transactions
{
    private m_client_id: number;
    private m_list: Array<Transaction>;

    constructor()
    {
        this.m_client_id = 0;
        this.m_list = [];
    }
    
    get clientId(): number { return this.m_client_id; }
    get list(): Array<Transaction> { return this.m_list; }

    set(data): void
    {
        //clear array
        this.m_list.splice(0)

        this.m_client_id = data.client_id;
        const transactions = [];
        for(let i=0; i< data.transactions.length; ++i)
        {
            const transaction = new Transaction();
            transaction.id              = data.transactions[i].id;
            transaction.value           = data.transactions[i].value;
            transaction.date            = data.transactions[i].created_at;
            transaction.type            = data.transactions[i].type;
            transaction.sub_type        = data.transactions[i].sub_type;
            transaction.observation     = data.transactions[i].observation;
            transaction.invoice_date    = data.transactions[i].invoice_date ? data.transactions[i].invoice_date : "N/A";
            
            if(data.transactions[i].user_id == null)
                transaction.user = "Sistema";
            else
                transaction.user  = data.transactions[i].worker_name;

            transactions.push(transaction);
        }
        this.m_list = transactions;
    }

    clear(): void
    {
        this.m_client_id = 0;
        this.m_list.splice(0);
    }
}

export default Transactions;





