import api from "@/scripts/modules/new_card/api/api"
import { AxiosResponse } from "axios";
import useGlobalStore, { GlobalStore } from "@/scripts/core/stores/global.store";
import ApiResult from "@/scripts/core/types/api_result";
import useNewCardStore, { NewCardStore } from "@/scripts/core/stores/new.card.store";
import useAuthStore, { AuthStore } from "@/scripts/core/stores/auth.store";
import DynamicObject from "@/scripts/core/types/dynamic";

class NewCardSystem
{
    newCardStore: NewCardStore;
    globalStore: GlobalStore;
    authStore: AuthStore;

    constructor()
    {
        this.newCardStore = useNewCardStore();
        this.globalStore = useGlobalStore();
        this.authStore = useAuthStore();
    }

    async searchByNif(nif: string): Promise<ApiResult>
    {
        this.globalStore.showLoading();

        return api.searchByNif(nif).then( 
            (response: AxiosResponse) => { 

                this.globalStore.hideLoading();

                //check data result
                if(response.status == 200)
                {
                    const data = response.data;
                    this.newCardStore.setClient(data.client);
                    this.newCardStore.setPersonalData(data.personal_data);
                    this.newCardStore.setTransactions(data.transactions);

                    return { result: true };
                }
                else if(response.status == 404)
                {

                    return { result: false, message: "invalid_client" };
                }
                else if(response.status == 400)
                {

                    return { result: false, message: "suspended_client" };
                }
            } 
        );
    }

    closeClient(): void
    {
        //clear the client data
        localStorage.client = false;
        this.newCardStore.clear();
    }

    async validateNewCard(card: string): Promise<ApiResult>
    {
        this.globalStore.showLoading();           
        //check if the card is valid
        return api.checkNewCard(card).then(
            (response: AxiosResponse) => 
            {
                this.globalStore.hideLoading();

                if(response.status == 200)
                    return { result: true };
                else
                    return { result: false };
            }
        );
    }

    async addNewCard(card: string, client_id: number): Promise<ApiResult>
    {
        this.globalStore.showLoading();            
        //check if the card is valid
        const extra_data: DynamicObject = {};
        extra_data.user_id = this.authStore.user.id;
        extra_data.shift_id = this.authStore.user.shiftId;

        return api.addNewCard(card, client_id, extra_data).then(
            (response: AxiosResponse) => 
            {
                this.globalStore.hideLoading();

                if(response.status == 200)
                    return { result: true };
                else
                    return { result: false };
            }
        );
    }
}

export default NewCardSystem;